<template>
  <div class="w-90 m-auto bdr bdr-2 bdw-1 bdc-bs mt-10 animate__animated animate__backInUp"  @click="callBack()">
    <div class="font-14 text-left bdw-1 bdc-bs w-100 ">
      <p class="t-hd-one p-10 ">{{name}}</p>
      <div class="w-94 m-auto pb-5">
        <el-image class="w-100 h-10rem" :src="url" fit="cover">
        </el-image>
      </div>
    </div>
    <div class="h-1rem text-left p-10 font-12 flex-row flex-jsb flex-ac curp">
      <span>查看详情</span>
      <i class="el-icon-arrow-right"></i>
    </div>

  </div>
</template>

<script>
export default {
  name: "myCommonBox",
  props:{
    name:'',
    url:'',
  },
  data() {
    return {};
  },
  methods:{
    callBack(){
      this.$emit('onClick');
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>