<template>
  <div class="flex-1 flex-col mb-20">
    <!-- <div class=" w-75rem  bgc-w m-auto flex-1 mt-50 ovf-h bdr-4 flex-col b-shadow p-20 "> -->
    <!-- <div class="p-20 flex-1"> -->
    <el-tabs @tab-click="handleClick" :value="activeName" type="border-card" class="h-80rem w-75rem m-auto mt-20">
      <el-tab-pane label="关于我们" name="1">
        <div class="tabsItem">
          <h2 class="tabsTitle">关于我们</h2>
          <div class="aboutUs">
            <ol>
              单位地址：项城市建设路
              <li>（一）贯彻落实党和国家关于文化工作的方针政策，研究拟订全市文化、广播电视和旅游政策措施并组织实施。</li>
              <li>（二）统筹规划全市文化、文物、广播电视和旅游发展，拟定发展规划并组织实施，推进文化和旅游融合发展，推进文化、文物、广播电视和旅游体制机制改革。</li>
              <li>（三）指导、管理全市文艺事业，指导艺术创作生产，扶持体现社会主义核心价值观、具有导向性代表性示范性的文艺作品，推动各门类艺术、各艺术品种发展。</li>
              <li>（四）负责全市非物质文化遗产保护，推动非物质文化遗产的传承、普及、弘扬和振兴，推进全市优秀传统文化传承发展。</li>
              <li>（五）负责全市公共文化、旅游事业发展，推进全市公共文化服务体系建设和旅游公共服务建设, 深入实施文化和旅游惠民工程。</li>
              <li>（六）指导、管理全市文物和考古工作，组织文物资源调查，开展文物保护和利用。</li>
              <li>（七）指导、管理全市博物馆、纪念馆工作，完善公共服务体系，负责社会文物管理、文物科技保护和对外交流工作，协调指导全市文物安全工作。</li>
              <li>（八）负责对全市各类广播电视机构进行业务指导和行业监管，指导电视剧行业发展和电视剧创作生产，指导网络视听节目服务管理，对全市广播电视节目覆盖、监测和安全播出进行监管。</li>
              <li>（九）统筹规划全市文化产业和旅游产业，组织实施全市文化和旅游资源的普查、挖掘、保护和利用工作，促进文化产业和旅游业发展。</li>
              <li>
                （十）管理全市重大文化和旅游活动，指导全市文化、文物、广播电视和旅游重点设施建设，组织全市文化和旅游整体形象宣传推广，促进文化产业和旅游产业对外合作、交流和市场推广，拟订旅游市场开发规划并组织实施，指导、推进全域旅游。
              </li>
              <li>（十一）指导、推进全市文化、文物、广播电视和旅游科技创新融合发展，推进行业信息化、标准化建设。</li>
              <li>
                （十二）指导全市文化和旅游市场发展，对文化和旅游领域的市场经营活动进行行业监管，推进文化和旅游行业信用体系建设，依法规范文化和旅游市场，会同有关部门履行文化、文物、广播电视和旅游行业安全监督责任；指导、监督、管理全市文化市场综合执法，维护市场秩序。
              </li>
              <li>
                （十三）关于职责分工。项城市文化广电和旅游局负责动漫和网络游戏相关产业规划、产业基地、项目建设、会展交易和市场监管，负责对影视动漫和网络视听节目中的动漫节目进行管理。项城市新闻出版局负责对出版环节的动漫、游戏出版物的网上出版发行进行管理。
              </li>
              <li>（十四）完成市委、市政府交办的其他事项。</li>
            </ol>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="联系我们" name="2">
        <div class="tabsItem">
          <h2 class="tabsTitle">联系我们</h2>
          <div class="connect">
            <div class="img" v-for="(item, index) in connectLis" :key="index">
              <div
                style="height:4rem;width:4rem;border-radius: 3rem;display: flex;align-items: center;justify-content: center;"
                :style="{ backgroundColor: item.color || '#ffffff' }">
                <i style="font-size: 2rem;color: #ffffff;" :class="item.icon"></i>
              </div>
              <div class="type">{{ item.type }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="帮助中心" name="3">
        <div class="tabsItem">
          <h2 class="tabsTitle">帮助中心</h2>
          <div>
            <p class="">
              <a href="http://xiangcheng.gov.cn/sitesources/xcs/page_pc/index.html">项城市人民政府</a>
            </p>
          </div>
        </div>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="标准规范" name="4">
        <div class="tabsItem">
          <h2 class="tabsTitle">标准规范</h2>
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="意见反馈" name="5">
        <div class="tabsItem">
          <h2 class="tabsTitle">意见反馈</h2>
          <addfeedBack />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>

import { addfeedBack } from "@/components/index"
export default {
  components: { addfeedBack },
  data() {
    return {
      activeName: '1',
      connectLis: [
        {
          img: require('@/assets/common/location.png'),
          type: "通信地址",
          text: '106国道附近',
          icon: 'el-icon-location-information',
          color: '#466dec'
        }, {
          img: require('@/assets/common/location.png'),
          type: "联系电话",
          text: '0394-4321058',
          color: '#b75e5e',
          icon: 'el-icon-phone-outline'
        }, {
          img: require('@/assets/common/location.png'),
          type: "邮箱地址",
          color: "#b731c6",
          text: '暂未公布',
          icon: 'el-icon-message'
        }, {
          img: require('@/assets/common/location.png'),
          type: "公众号",
          color: "#27822c",
          text: '项城市文化局',
          icon: 'el-icon-files'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {
    handleClick() { },
  }
}
</script>
<style scoped lang="less">
@import "../../theme/myStyle.less"; //全局样式

.container {
  flex: 1;
}

.tabsItem {
  width: 100%;
  min-height: 30rem;
  flex: 1;


  .tabsTitle {
    margin-bottom: 20px;

  }
}

//联系我们 
.connect {

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  margin: 0 auto;

  .img {
    cursor: pointer;
    height: 12rem;
    width: 9rem;
    background-color: #f6f6f6;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .type {
    margin-top: 10px;
    font-size: 18px;
  }

  .text {
    font-size: 14px;
    margin-top: 5px;
    color: #999999;
  }
}

.aboutUs {
  text-align: left;

  ol {
    margin-top: 20px;
    list-style: circle;

    li {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>