import axios from "axios";//引入axios
import ApiContainer from "@/api/url";
import store from '@/store/index'
import router from '@/router'
import { getToken } from '@/util/auth';
import { Message,Loading } from 'element-ui';
//封装 get方法,并且暴露出去
// 配置接口
const instance = axios.create({
  baseURL: ApiContainer.http,
  timeout: 5000,

});
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  if (getToken()) {
    // 设置令牌请求头
    config.headers['token'] = getToken()
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // return response;
  if (response.data.state == 505) {

    // router.push({
    //   path: "/user", query: { loginState: 0 }
    // }, () => { }, () => { })
  }
  return response
}, function (error) {
  return Promise.reject(error);
});
// 下面是请求方法--get
export const get = (url, params) => {

  const promise = new Promise((resolve, reject) => {
    const loading = Loading.service({
      lock: true,
      text: '正在加载',
      spinner: 'el-icon-loading',
      background: 'rgba(255,255, 255, 0.7)'
    });
    instance({
      method: 'get',
      url: url,
      params: params
    }).then(response => {
      loading.close();
      resolve(response && response.data)//只要返回data里面的数据就ok
    }).catch(error => {
      loading.close();
      let response = error.response;
      if (response.data.state == 560) {
        Message.warning(response.data.msg);
      } else if (response.data.state == 505) {
        Message.warning(response.data.msg);
      } else if (response.data.state == 500) {
        Message.warning('网络错误请稍后重试');
      } else {
        reject(error)
      }
    })
  })
  return promise;
};
// 下面是请求方法--post
export const post = (url, data) => {
  const promise = new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: url,
      data: data
    }).then(response => {
      resolve(response && response.data)
    }).catch(error => {
      reject(error)
    })
  })
  return promise;
}

// 上传图片
export const postImg = (url, file) => {
  const promise = new Promise((resolve, reject) => {
    instance({
      url: url,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: file,
    }).then(response => {
      resolve(response && response.data)
    }).catch(error => {
      reject(error)
    })
  })
  return promise;
}
