<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
      <div class="mt-30 flex-row lex-jsb flex-wrap pb-30" v-if="venueList.length">
        <venueItem class="mt-20" @onClick="goPage(`/venueDetails?id=${item.id}`)" v-for="item in venueList" :key="item.id"
          :venueName="item.name" :location="item.location" :contactMan="item.contactMan" :contactTel="item.contactTel"
          :openTime="item.openTime" :headPic="item.headPic" :vrId="item.vrId" @onCheckVr="goPage(`/vr?id=${item.vrId}`)"/>
      </div>
      <div class="mt-30  pb-30 bgc-w" v-else>
        <el-empty description="暂无场馆"
          image="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/miss.png"></el-empty>
      </div>
      <div class="mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { myRadio, myBreadCrumb, venueItem } from "@/components/index.js";
import venueApi from "@/api/request/venue.js";
import commmonApi from "@/api/request/common.js";
export default {
  name: "venueList",
  components: { myRadio, myBreadCrumb, venueItem },
  data() {
    return {
      venueList:[],
      // 顶部的目录
      menuList: [
        {
          // path: "/Activity",
          name: "文化馆",
        },
        {
          // path: "/Activity",
          name: "场馆列表",
        },
      ],
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      searchParams: {},
      selection: [],
      queryInfo: {
        pageNo: 1,
        pageSize: 8,
      },
      total: 0,
    };
  },
  created() {
    this.getClass();
    this.getList();
  },
  methods: {
    changeType(e) {
      this.searchParams[e.type.value] = e.data
      this.getList(this.searchParams)
    },
    getList(params = {}) {
      venueApi.getVenueList({
        isDelete: '0',
        ...this.searchParams,
        ...params,
        ...this.queryInfo,
      }).then(res => {
        if (res.success) {
          this.venueList = res.data.list
          this.total = res.data.searchParams.total
        }
      })
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    // 获取顶部的选项
    getClass() {
      commmonApi.adminKey({
        keyName: 'venue',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map((item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map((item2, index2) => {
                arr.push({
                  label: item2.keyDescribe,
                  value: item2.keyValue
                })
              })

              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    goPage(e) {
      this.$router.push(e);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.activity-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}
</style>