<template>
  <div class=" body-container w-80 m-auto mt-20 flex-1 flex-row flex-jsb">
    <div class="map_container">
      <div id="index" class="h-100 w-100">
        <baidu-map class="map w-100 h-100" :center="center" :zoom="zoom" :dragging="true" :scroll-wheel-zoom="true"
          @ready="handler" v-if="list.length">
          <bm-marker v-for="(item, index) in list" :key="index" :position="{ lng: item.log, lat: item.lat }"
            :dragging="false" animation="BMAP_ANIMATION_BOUNCE" @click="showName(item)">
            <bm-label :content="item.name" :labelStyle="{ color: '#000000', fontSize: '12px' }" />
          </bm-marker>
        </baidu-map>
      </div>
      <div class="list">
        <div class="" v-for="(item, index) in list" :key="index">
          <div @click="setCenter(item)" style="width: 100px; height: 80px;margin:0 2px;overflow: hidden;" >
            <el-image class="curp pic h-100 w-100"  :src="item.imgArr.split(',')[0]" fit="cover"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <el-descriptions title="用户信息" direction="vertical" :column="3">
        <el-descriptions-item label="图片展示" :span="3">
          <el-carousel height="150px" width="100%" v-if="showData.imgArr">
            <el-carousel-item v-for="item in showData.imgArr.split(',')" :key="item">
              <el-image style="width: 100%; height: 100%" fit="cover" :src="item" :preview-src-list="srcList">
              </el-image>
            </el-carousel-item>
          </el-carousel>
        </el-descriptions-item>
        <el-descriptions-item label="地区名称" :span="2">{{ showData.name }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{ showData.tel }}</el-descriptions-item>
        <el-descriptions-item label="联系地址">{{ showData.location }}</el-descriptions-item>
      </el-descriptions>

    </div>
  </div>
</template>

<script>
import cultureApi from '@/api/request/culture';
export default {
  name: 'ktv',
  data() {
    return {
      center: { lng: 114.63, lat: 0 },
      zoom: 12,
      srcList: [],
      queryInfo: {
        pageSize: 10,
        pageNo: 0,
      },
      list: [],
      showData: {},
    }
  },
  created() {
    this.getList();
  },
  methods: {
    setCenter(item) {
      this.center.lng = item.log;
      this.center.lat = item.lat;
      this.showData = item;
      this.srcList = item.imgArr.split(",")
    },
    handler({ BMap, map }) {
      this.center.lng = 114.63;
      this.center.lat = 33.63;
      this.zoom = 12;
    },
    showName(e) {
      console.log("123", e)
      this.showData = e;
      this.srcList = e.imgArr.split(",")
    },
    getList() {
      cultureApi.getEnforcementList({
        ...this.queryInfo,
        type: "网吧",
      }).then(res => {
        if (res.success) {
          this.list = res.data.list;
          this.showData = res.data.list[0]
      this.srcList = res.data.list[0].imgArr.split(",")

        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import url("../../theme/myStyle.less");

.body-container {
  min-width: 1024px;
  height: 100%;
}

.map_container {
  width: 60%;
  position: relative; // height: 300px;

  // height: 100%;
  .list {
    bottom: 0px;
    padding: 10px 0;
    width: 100%;
    overflow-x: scroll;
    height: 100px;
    position: absolute;
    display: flex;
    flex-direction: row;
  }
}

.list {
  width: 34%;
  background-color: #ffffff;
  padding: 20px 2%;
  // height: 100%;
}
.pic{
  transition: all .3s;
}
.pic:hover{
  height: 120%;
  margin-top: -10%;
  width: 120%;
  margin-left: -10%;
  
}
</style>