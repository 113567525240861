<template>
  <div class="flex-1">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <!-- <div class=" mt-20">
        <myRadio :selection="selection" />
      </div> -->
      <div class=" flex-row flex-jsb mt-30 pb-30 w-100">
        <div class="flex-col flex-wrap  w-70 bgc-w ">
          <div v-if="cultList.length">
            <culturalStationItem class="curp " v-for="(item) in cultList" :key="item.id" :name="item.name"
              :members="item.members" :time="item.openTime + '-' + item.closeTime" :tel="item.tel"
              :img=" item.img" @click="goPage('/cultureDetail', { id: item.id })" />
          </div>
          <el-empty v-else description="暂无镇（街道）文化站信息，请稍后查看"></el-empty>
        </div>
        <div class="mh-10rem bgc-w w-25 bdr-4 pb-20">
          <mySection title="推荐镇（街道）文化站" />
          <div v-if="pushList.length" style="height:auto">
            <!-- <myCommonBox /> -->
            <div v-for="(item, index) in  pushList " :key="index">
              <div class="pushItem" @click="goPushItem(item)">
                <div class="tags">
                  <el-tag type="warning" effect="plain"> {{ item.isStree == 1 ? '镇（街道）文化站' : '村（社区）文化服务中心' }} </el-tag>
                </div>
                <el-image style="width: 100%; height: 10em" :src="item.img" fit="cover"></el-image>
                <div class="pushItemName">
                  <div>{{ item.name }}</div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <el-empty v-else description="暂无推荐镇（街道）文化站"></el-empty>
        </div>
      </div>
      <div class="mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  myRadio,
  myBreadCrumb,
  mySection,
  culturalStationItem,
  myCommonBox,
} from "@/components/index.js";
import commmonApi from "@/api/request/common";
import cultureApi from "@/api/request/culture";

export default {
  name: "culturalStation",
  components: {
    myRadio,
    myBreadCrumb,
    mySection,
    culturalStationItem,
    myCommonBox,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      menuList: [
        {
          path: "",
          name: "社区文化站",
        },
        {
          path: "",
          name: "镇（街道）文化站",
        },
      ],
      selection: [
        {
          type: "地区",
          data: [
            "不限",
            "街道1", "街道2"
          ],
        },
      ],
      queryInfo: {
        pageNo: 0,
        pageSize: 8,
      },
      total: 0,
      searchParams: {},
      cultList: [],
      pushList:[],
    };
  },
  created() {
    this.getList();
    this.getPushList();
  },
  methods: {
       // 获取推荐列表
       getPushList() {
      cultureApi.cultureList({
        push: '1',
        pageSize: 3,
        pageNo: 1,
      }).then(res => {
        if (res.success) {
          this.pushList = res.data.list;
        }
      })
    },
    goPage(e, query) {
      if (query) {
        this.$router.push({ path: e, query: query })
      } else {
        this.$router.push(e)
      }
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getList() {
      cultureApi.cultureList({
        ...this.queryInfo,
        isStree: 1,
      }).then(res => {
        if (res.success) {
          this.cultList = res.data.list
          this.total = res.data.searchParams.total
        }
      })
    },
  }
};
</script>
  
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.pushItem {
  width: 94%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  text-align: left;
}

.tags {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 10;
}

.pushItemName {
  height: 3em;
  border-bottom: 1px solid #efefef;
}
</style>