<template>
  <div class="h-100 w-100">
    <el-image :src="url" fit="cover">
      <div slot="placeholder" class="image-slot">
        <span class="dot">加载中...</span>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  name: "myImage",
  props: {
    url: {
      default: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      type: String,
    }
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.dot {
  color: @RegularText;
}

.el-image {
  height: 100%;
  width: 100%;
}
</style>