<template>
  <div class="flex-col h-100 w-100">
    <div class="flex-1 ">
      <el-table :data="listData" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="处理时间">
                <span>{{ props.row.resultTime }}</span>
              </el-form-item>
              <el-form-item label="回复内容">
                <span>{{ props.row.result }}</span>
              </el-form-item>
              <el-form-item label="反馈状态 ">
                <span>{{ props.row.state }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="反馈联系人" prop="name" width="200">
        </el-table-column>
        <el-table-column label="反馈时间" prop="createTime" width="200">
        </el-table-column>
        <el-table-column label="反馈内容" prop="title" width="200">
        </el-table-column>
        <el-table-column label="内容详情" prop="reson">
        </el-table-column>
        <el-table-column label="反馈状态" prop="step" width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.step === '0' ? 'primary' : 'success'" disable-transitions>{{
              scope.row.step === '0' ? '等待处理' : '处理成功'
            }}</el-tag>
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
    </div>
    <el-pagination background layout="prev, pager, next" :total="total" class="mt-10 " @size-change="changeSize"
      @current-change="changePage">
    </el-pagination>
  </div>
</template>


<script>
import commmonApi from '../../api/request/common';
export default {
  data() {
    return {
      listData: [],
      userId: '',
      total: 0,
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      }
    };
  },
  mounted() {
    this.getUserInfo();
    this.getList()
  },
  created() {
    this.$nextTick(() => {

    })
  }, methods: {
    changeSize() { },
    // 修改页码
    changePage(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userId = userInfo.id
    },
    getList() {
      commmonApi.feedBackList({
        ...this.queryInfo,
        userId: this.userId
      }).then(res => {
        this.listData = res.data.list;
        this.total = res.data.searchParams.total
      })
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>
<style>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
