<template>
  <div class="flex-col h-100 w-100">
    <div class="flex-1">
      <el-table ref="filterTable" :data="tableData" style="width: 100%" stripe>

        <el-table-column prop="activityName" label="活动名称" width="180">
        </el-table-column>
        <el-table-column prop="activePlace" label="地址">
        </el-table-column>
        <el-table-column prop="createTime" label="报名时间">
        </el-table-column>
        <template slot="empty">
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
    </div>
    <el-pagination background layout="prev, pager, next" :total="total" class="mt-10 " @size-change="changeSize"
      @current-change="changePage" >
    </el-pagination>
  </div>
</template>

<script>
import activityApi from "@/api/request/activity";
export default {
  name: "booking",
  props: {},
  data() {
    return {
      queryInfo: {
        pageSize: 8,
        pageNo: 0,
      },
      total: 0,
      tableData: [
      ],
    };
  },
  mounted() {
    this.getActivityEnrollList();
  },
  methods: {
    changeSize(e) {

    },
    changePage(e) {
      this.queryInfo.pageNo = e;
      this.getActivityEnrollList();
    },
    getActivityEnrollList() {
      console.log('123123123123',this.$store.state.user.userInfo)
      let userInfo = this.$store.state.user.userInfo;
      this.$nextTick(() => {
        activityApi.getActivityEnrollList({
          ...this.queryInfo,
          userId: userInfo.id
        }).then(res => {
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.searchParams.total
          }
        })
      })
    },
    callBack() {
      this.$emit("changeInfo");
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter("date");
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },

  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>