<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <!-- 顶部标题 -->
      <div class="w-100 ml-5 h-20rem bgc-w mt-20 flex-row flex-ac bdr-4 b-shadow">
        <el-image class="h-90 w-30rem ml-20 bdr-4" :src="scenicDetails.pic" fit="cover"></el-image>
        <div class="h-90 ml-20  flex-col font-wl flex-1 text-left">
          <div class="color-p font-1_5rem ">{{ scenicDetails.name }}</div>
          <!-- <div class="color-gray2 font-0_8rem mt-20">景点数目：12个</div> -->
          <div class="color-gray2 font-0_8rem ">开放时间:{{ scenicDetails.openTime }} ~ {{ scenicDetails.closeTime }}</div>
          <!-- <div class="color-gray2 font-0_8rem mt-10 ">联系电话:178XXXXXXXX</div> -->
          <div class="color-gray2 font-0_8rem mt-10 ">景区名称：{{ scenicDetails.belongTo }}</div>
          <div class="color-gray2 font-0_8rem mt-10 pr-30 t-hd-four flex-1">简介：<span>{{ scenicDetails.intro }}</span>
          </div>

          <div class="flex-row flex-je mr-30">
            <div class="mt-20">
              <el-button icon="el-icon-thumb" circle type="primary" plain @click="like"></el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ scenicDetails.likeNum }}</span>
            </div>
            <div class="mt-20 ml-20">
              <el-button icon="el-icon-share" circle type="primary" plain @click="sharePage(scenicDetails)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 下面 -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50">
        <div class="w-65  ph-10 bgc-w mh-40h  ">
          <mySection title="景区介绍" />
          <div class="sign_info">
            <div v-if="scenicDetails.content" v-html="scenicDetails.content"></div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
          <el-divider></el-divider>
          <mySection title="图片欣赏" class="mt-10" />
          <div class="">
            <div v-if="imgList">
              <el-carousel indicator-position="outside">
                <el-carousel-item v-for="(item, index) in imgList" :key="index">
                  <el-image :src="item.imgUrl" class="h-100">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <el-empty v-else description="暂无具体景点"></el-empty>
          </div>
        </div>
        <div class="w-30 bgc-w ph-10 h-100">
          <mySection title="热门景区" />
          <div class="">
            <div v-if="pushList.length">
              <div v-for="(item, index) in pushList" :key="index">
                <myCommonBox :name="item.name" :url="item.pic" @onClick="goPlan(item)" />
              </div>
            </div>
            <el-empty v-else description="暂无热门景区"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  myRadio,
  myBreadCrumb,
  activityItem,
  mySection,
  myCommonBox,
} from "@/components/index.js";
import scenicApi from "@/api/request/scenic";
import commmonApi from "@/api/request/common";
import mapApi from "@/api/request/map";
import share from "@/util/share";
export default {
  name: "SpotDetails",
  components: { myRadio, myBreadCrumb, activityItem, mySection, myCommonBox },
  data() {
    return {
      scenicDetails: {},
      imgList: [],
      pushList: [],
      // 顶部的目录
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      menuList: [
        {
          path: "/ScenicSpot",
          name: "景区列表",
        },
        {
          path: "/SpotDetails",
          name: "景区详情",
        },
      ],
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.id) {
      this.getDetails(query)
      this.getPush();
      this.getImg(query.id)
    }
  },
  methods: {
    getImg(e) {
      mapApi.getMapImagelist({
        id: e
      }).then(res => {
        if (res.success) {
          this.imgList = res.data
        }
      })
    },
    goPlan(e) {
      this.$router.push({ path: '/SpotDetails', query: { id: e.id } });
      this.getDetails(e)

    },
    // 获取推荐数据
    getPush() {
      scenicApi.getScenicPointList({
        push: 1,
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        if (res.success) {
          this.pushList = res.data.list;
        }
      })
    },
    // 用户点击喜欢
    like() {
      commmonApi.likeRecord({
        partName: '文化景区',
        partId: this.scenicDetails.id,
        isLike: '1',
      }).then(res => {
        if (res.success) {
          scenicApi.updateScenicPoint({
            likeNum: this.scenicDetails.likeNum + 1,
            id: this.scenicDetails.id
          }).then(res2 => {
            if (res2.success) {
              this.$message.success("点赞成功")
              this.scenicDetails.likeNum = this.scenicDetails.likeNum + 1;
            }
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    sharePage(e) {
      share.share2QQ(
        {
          url: 'http://yuyitang.xyz:3333',
          desc: e.content,
          title: e.name,
        }
      )
    },
    getDetails(e) {
      scenicApi.getScenicPointInfoById({
        id: e.id
      }).then(res => {
        if (res.success) {
          this.scenicDetails = res.data;
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.activity-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}

.sign_info {
  min-height: 20rem;
  overflow-y: scroll;
  // max-height: 25rem;
  padding: 20px 30px;
}
</style>