<template>
  <div class="regiest">
    <div class="bgimg">
      <el-image style="width: 100%; height: 100%" :src="url" fit="cover"></el-image>
    </div>
    <div class="typed">
      <Typed :typedList="['欢迎使用', '项城文旅', '在这里您可以获得您想要的信息']" :loop="false" :speed="350" v-if="true" />
    </div>
    <div class="regiest-box">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-position="left" class="demo-ruleForm">
        <el-form-item label="用户昵称" prop="wx_name">
          <el-input v-model="ruleForm.wx_name" placeholder="您希望别人如何称呼您"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="tel">
          <el-input v-model="ruleForm.tel" placeholder="请输入手机号" type="number">
            <template slot="append">
              <div class="curp" v-if="!isSend" @click="sendMessage">发送验证码</div>
              <div class="curp" v-else @click="showToast">{{ count_time + '秒后重发' }}</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="text">
          <el-input v-model="ruleForm.text" placeholder="请输入验证码" type="number"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input onkeyup="value=value.replace(/[^\x00-\xff]/g, '')" v-model="ruleForm.username" placeholder="请输入登录账号"
            type="email"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password" placeholder="请输入登录密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">注 册 账 号</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { Typed } from "@/components/index";
import { setToken } from "@/util/auth"
import commmonApi from '../../api/request/common';
export default {
  components: { Typed },
  data() {
    return {
      url: require("@/assets/common/bgcimg1.png"),
      ruleForm: {},
      rules: {
        wx_name: [
          { required: true, message: '您希望别人如何称呼您', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '在这里设定您的账号信息', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '在这里输入你的密码，请勿让他人知道！', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号！', trigger: 'blur' },
          { min: 8, max: 13, message: '手机号不准确', trigger: 'blur' }
        ],
        text: [
          { required: true, message: '请输入验证码！', trigger: 'blur' },
          { min: 6, max: 6, message: '验证码长度不准确', trigger: 'blur' }
        ],
      },
      isSend: false,//是否已经发送验证码
      count_time: 60,//验证码发送倒计时
    }
  },
  created() {
  },
  methods: {
    showToast() {
      this.$message('请' + this.count_time + '秒后重试')
    },
    sendMessage() {
      // 先验证一下用户表里面是否有该用户
      if (this.ruleForm.tel) {
        commmonApi.checkHasUser({
          tel: this.ruleForm.tel
        }).then(data1 => {
          if (data1.success) {
            commmonApi.sms({
              phone: this.ruleForm.tel
            }).then(res => {
              if (res.success) {
                this.isSend = true;
                let time = setInterval(() => {
                  if (this.count_time > 0) {
                    this.count_time--
                  } else {
                    clearInterval(time);
                    this.count_time = 60;
                    this.isSend = false;
                  }
                }, 1000);
              }
            })
          } else {
            this.$message({
              type: 'warning',
              message: data1.msg,
              duration: 3000,
            })
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: "请输入手机号",
          duration: 3000,
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          commmonApi.register({
            ...this.ruleForm,
          }).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '注册成功!',
                duration: 3000,
                onClose: () => {
                  localStorage.setItem('token', res.data.token)
                  localStorage.setItem('userInfo', JSON.stringify(res.data));

                  setToken(res.data.token)
                  this.$store.commit('user/SET_USERINFO', res.data)
                  this.$message.close()
                  this.$router.push({
                    path: 'user'
                  })
                }
              });
            } else {
              this.$message(res.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../../theme/myStyle.less");

.regiest {
  position: relative;
  min-width: 1350px;

  .bgimg {
    // position: absolute;
    height: 100vh;
    // width: 100vw;
    overflow: hidden;
  }

  .regiest-box {
    position: absolute;
    padding: 2rem 4rem;
    top: 50%;
    // left: 50%;
    right: 6rem;
    margin-top: -20rem;
    // margin-left: -14rem;
    min-height: 30rem;
    width: 24rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 7px 0px #7b7b7b;
    background-color: #a162221c;
  }
}

.typed {
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 10rem;
  left: 10rem;
  letter-spacing: 3px;
  color: #ffffff;

  /* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
  // @font-face {
  // font-family: "阿里妈妈刀隶体 Regular";font-weight: 400;src: url("../../assets/font/alimama/AlimamaDaoLiTi.woff2") format("woff2"),
  // url("../../assets/font/alimama/AlimamaDaoLiTi.woff")format("woff");
  // font-display: swap;
  // }
  @font-face {
    font-family: alimamma;
    src: url("../../assets/font/alimama/AlimamaDaoLiTi.ttf");
  }

  /* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
  // font-family: url("//at.alicdn.com/wf/webfont/ZZf4svdpkse0/dpdfxLxZAN6a.woff2") format("woff2"),
  //   url("//at.alicdn.com/wf/webfont/ZZf4svdpkse0/0I05gVUmEUF9.woff") format("woff");
  ;
}
</style>