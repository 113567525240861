<template>
  <div class="feedback">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="反馈事宜">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="联系称呼">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="邮箱地址">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="建议反馈">
        <el-input type="textarea" v-model="form.reson"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import commmonApi from '../../api/request/common'

export default {
  data() {
    return {
      form: {}
    }
  },
  methods: {
    onSubmit() {
      commmonApi.addFeedBack({
        ...this.form
      }).then(res => {
        if (res.success) {
          this.$message.success({
            message: '反馈成功',
            type: 'success',
            onClose: () => {
              this.$router.push("/home")
            }
          })
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.feedback {
  width: 40rem;
  margin: 0 auto;
}
</style>