<template>
  <div class=" h-8rem flex-row flex-ac flex-jc cal animate__animated animate__bounceInUp" @click="callBack()">
    <div class="signImg">
      <el-image class=" itemImg" :src="img" fit="cover">
      </el-image>
    </div>
    <div class="h-80 flex-col flex-as flex-jcs pl-30 w-100">
      <p class="font-1rem color-sp name ">{{ name }}</p>
      <p class="font-12 color-gray3 mt-10 " v-if="time.indexOf('null')">发布时间：{{ time }} </p>
      <p class="font-12 color-gray3 ">工作人员：{{ members }}</p>
      <p class="font-12 color-gray3">联系电话：{{ tel }}</p>
      <div class="flex-row w-90 flex flex-ac font-12 color-gray3   flex-as  ">
        <p class="">内容介绍：</p>
        <p class="w-80 text-left t-hd-two"> {{ intro }}</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "visitPlanItem",
  props: {
    name: {
      type: String,
      default: ''
    },
    auth: {
      type: String,
      default: ''
    },
    members: {
      type: String,
      default: '',
    },
    tel: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      callBack() {
        this.$emit("click")
      }
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.cal {
  padding: 15px 0px;
  border-bottom: 1px solid #efefef;
  margin-left: 3%;
  width: 94%;
}

.signImg {
  width: 20rem;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.itemImg {
  transition: all .3s;
  height: 100%;
  width: 100%;
}

.cal:hover .itemImg {
  transform: scale(1.1);

}

.name {
  transition: all .2s;
}

.cal:hover .name {

  transform: scale(1.1);

}
</style>