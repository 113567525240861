<template>
  <div class="flex-col h-100 w-100">
    <div class="flex-1">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="预约时间" label="bookDate" width="150">
          <template slot-scope="scope">
            <p>{{ scope.row.bookDate.split(" ")[0] }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="场馆信息"> -->
        <el-table-column prop="venueName" label="场馆名称" width="120">
        </el-table-column>
        <!-- <el-table-column label="预约内容"> -->
        <el-table-column prop="bookStartTime" label="开始时间">
        </el-table-column>
        <el-table-column prop="bookEndTime" label="结束时间">
        </el-table-column>
        <el-table-column prop="peopleNum" label="预计人数">
        </el-table-column>
        <el-table-column prop="zip" label="状态" width="120">
          <template slot-scope="scope">
            <div>
              <el-tag v-if="scope.row.isAgree == 0" type="info">等待通过</el-tag>
              <el-tag v-else-if="scope.row.isAgree == 1" type="success">预约成功</el-tag>
              <el-tag v-else-if="scope.row.isAgree == 2" type="danger">预约失败</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="whyRes" label="结果原因" width="150">
          <!-- </el-table-column> -->
        </el-table-column>
        <!-- </el-table-column> -->
        <template slot="empty">
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
    </div>

    <el-pagination background layout="prev, pager, next" :total="total" class="mt-10 " @size-change="changeSize"
      @current-change="changePage">
    </el-pagination>
  </div>
</template>

<script>
import venueApi from "@/api/request/venue";
export default {
  name: "booking",
  props: {},
  data() {
    return {
      queryInfo: {
        pageSize: 8,
        pageNo: 0,
      },
      total: 0,
      tableData: [
      ],
    };
  },
  mounted() {
    let token = this.$store.state.user.token;
    console.log(token)
    if (token) {
      this.getList();
    }
  },
  created() {
  },
  methods: {
    changeSize(e) {

    },
    changePage(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getList() {
      venueApi.queryBookListById({
        ...this.queryInfo
      }).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.searchParams.total;
        }
      })
    },
    callBack() {
      this.$emit("changeInfo");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>