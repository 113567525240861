<template>
  <div class=" bgc-p bdr-4 p-10 mt-10">
    <div class="list">
      <div class=" flex-row flex-jsb">
        <h3 class="color-w">汇总栏</h3>
        <!-- <div class="flex">1</div> -->
      </div>
      <div class=" p-10">
        <ul>
          <li class=" List_li flex-row flex-jsb font-18" v-for="(item, index) in list" :key="index">
            <p class="color-sp">{{ item.name }}</p>
            <p class="color-lp">{{ addCommas(item.data) }}</p>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'list',
  props: {},
  data() {
    return {
      list: [{ name: '活动数据', data: 3212 }, { name: '昨日访问量', data: 412242 },{name:'用户数',data:1523}],
    }
  },
  mounted() {

  },
  created() { },
  methods: {
    addCommas(number) {
  const str = Math.abs(number).toString();
  const withCommas = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result = number < 0 ? '-' + withCommas : withCommas;
  
  return result;
}

  }
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.list {

  height: 7.6rem;
  // padding: 1rem;
}

.List_li {
  margin-top: 0.3rem;
}
</style>