<!-- 文化动态更多页面 -->
<template>
  <div class="container">
    <div class="w-75rem m-auto">
      <!-- <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div> -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50">
        <div class="w-100 ph-10 bgc-w mh-50h  ">
          <ul class="list-ul" v-if="dataList.length">
            <li class="list-li animate__animated animate__backInUp" v-for="(item, index) in dataList" :key="index"
              @click="goPage(item)">
              <div class="li-item">
                <el-image :src="item.bgcImg" :preview-src-list="[item.bgcImg]" fit="cover "
                  style="width: 240px; height: 150px">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>

                <div class="word">
                  <div class="name t-hd-two">{{ item.title }}</div>
                  <div class="details t-hd-two">{{ item.intro }}</div>
                  <div class="info">
                    <div class="secondeInfo">来源：项城市公众号</div>
                    <div class="secondeInfo">{{ item.readNum }}次阅读</div>
                    <div class="secondeInfo">{{ item.createTime }}</div>
                  </div>
                </div>
                <div class="icon"></div>
              </div>
            </li>
          </ul>
          <div class="empty" v-else>
            <el-empty description="暂无内容"></el-empty>
          </div>
          <div class="mt-20">
            <el-pagination background layout="prev, pager, next" :total="searchParams.total" :current-page.sync="searchParams.pageNo"
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-backtop target=".container"></el-backtop>
  </div>
</template>

<script>

import {
  myRadio,
  myBreadCrumb,
  activityItem,
  mySection,
} from "@/components/index.js";
import activityApi from '@/api/request/activity.js';
export default {
  components: { mySection, myBreadCrumb },
  name: "tenseMore",
  data() {
    return {
      menuList: [
        {
          path: "/Activity",
          name: "文化活动",
        },
        {
          // path: "/Activity",
          name: "活动列表",
        },
      ],
      dataList: [],
      searchParams: {
        pageSize: 10,
        pageNo: 0,
        total: 0,
      }
    }
  },
  mounted() {
    this.getTrends();
  },


  methods: {
    goPage(e) {
      console.log("e", e);

      if (e.isWeb == 1) {
        activityApi.updateTrends({
          id: e.id,
          readNum: e.readNum + 1,
        }).then(res => {
          if (res.success) {
            this.$router.push({
              path: 'tenseMore/tenseDetails',
              query: { id: e.id }
            })
            e.readNum = e.readNum + 1;
          }
        })
      } else {
        this.$confirm('此操作将跳转至其他页面', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          activityApi.updateTrends({
            id: e.id,
            readNum: e.readNum + 1,
          }).then(res => {
            if (res.success) {
              window.location.href = e.url
            }
          })
        })
      }
    },
    handleCurrentChange(e) {
      this.searchParams.pageNo = e;
      this.getTrends();
      console.log(e);
    },
    // 获取列表
    async getTrends() {
      this.dataList = [];
      activityApi.getTrendsList({
        pageSize: this.searchParams.pageSize,
        pageNo: this.searchParams.pageNo,
      }).then(res => {
        if (res.success) {
          this.dataList = res.data.list
          this.searchParams = res.data.searchParams
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.container {
  margin-top: 86px;

  .list-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    .list-li {
      width: calc(100% - 40px);
      border-bottom: 1px solid #efefef;
      padding: 20px;
    }

    .li-item {
      display: flex;
      flex-direction: row;
      text-align: left;
      position: relative;
      align-content: center;

      .name {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #000;
        margin-left: 20px;
        width: 35em;
        overflow: hidden;
        letter-spacing: 2px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        cursor: pointer;
      }

      .word {
        display: flex;
        flex-direction: column;
        // justify-content: center;
      }

      .details {
        margin-top: 10px;
        line-height: 24px;
        margin-left: 20px;
        font-size: 18px;
        width: 45em;
        color: #999999;
      }

      .info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        line-height: 22px;
        margin-left: 20px;
        position: absolute;
        bottom: 0px;

        .secondeInfo {
          font-size: 15px;
          color: #999;
          padding-right: 24px;
        }
      }
    }
  }
}
</style>