<template>
  <div class="flex-1">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
      <div class=" flex-row flex-jsb mt-30 pb-30 w-75rem h-100">
        <div class=" flex-row flex-wrap" v-if="true">
          <digitalSourceItem v-for="(item, index) in digitalList" :key="index" @onClick="openDigital(item)"
            :name="item.digitalName" :type="item.digitalType" :intro="item.digitalIntro" :url="item.headImg" />
        </div>
        <el-empty v-else description="暂无相关数字资源，请稍后"></el-empty>
      </div>
      <div class="mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="showDetails.digitalName" :visible.sync="pdfVisible" width="900px" center>
      <p class="details">{{ showDetails.digitalIntro }}</p>
      <iframe :src="showDetails.digitalSource + '?page=hsn#toolbar=0'" height="600" width="100%"></iframe>
    </el-dialog>
    <el-dialog :title="showDetails.digitalName" :visible.sync="videoVisible" width="900px" center>
      <p class="details">{{ showDetails.digitalIntro }}</p>

      <video class="videoDialog" :src="showDetails.digitalSource" controls height="600" width="100%"></video>
    </el-dialog>
    <el-dialog :title="showDetails.digitalName" :visible.sync="imgVisible" class="imgDialog" width='600px' center>
      <p class="details">{{ showDetails.digitalIntro }}</p>

      <el-image style="height: 300px; margin:0 auto;" :src="showDetails.digitalSource" fit="cover"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import {
  myRadio,
  myBreadCrumb,
  mySection,
  visitPlanItem,
  myCommonBox,
  digitalSourceItem
} from "@/components/index.js";
import digitalApi from "@/api/request/digital"
import commmonApi from "@/api/request/common";
export default {
  name: "digitalSource",
  components: { myRadio, myBreadCrumb, mySection, visitPlanItem, myCommonBox, digitalSourceItem },
  data() {
    return {
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      queryInfo: {
        pageNo: 1,
        pageSize: 8,
      },
      pdfVisible: false,
      videoVisible: false,
      imgVisible: false,
      showDetails: {},
      total: 0,
      digitalList: [],
      searchParams: {},
      menuList: [
        {
          path: "",
          name: "数字资源",
        },
        {
          path: "",
          name: "资源列表",
        },
      ],
      selection: [],
    };
  },
  created() {
    this.getList();
    this.getClass();
  },
  methods: {
    // 打开资源
    openDigital(e) {
      console.log(e)
      this.showDetails = e;
      if (e.digitalType == '电子书') {
        this.pdfVisible = true;
      } else if (e.digitalType == '视频') {
        this.videoVisible = true;
      } else {
        this.imgVisible = true;
      }
    },

    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    changeType(e) {
      this.searchParams[e.type.value] = e.data
      this.getList(this.searchParams)
    },
    getClass() {
      commmonApi.adminKey({
        keyName: 'digital',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map((item2, index2) => {
                arr[index2] = {
                  label: item2.keyDescribe,
                  value: item2.keyValue
                }
              })
              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    getList(params = {}) {
      digitalApi.getDigitalList({
        ...params,
        ...this.searchParams,
        ...this.queryInfo,
      }).then(res => {
        if (res.success) {
          this.total = res.data.searchParams.total
          this.digitalList = res.data.list;

        }
      })
    },
  }
};
</script>
  
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.videoDialog {
  background-color: #000000;
}
.details{
  text-align: left;
    width: 100%;
    text-indent: 2em;
}
::v-deep .el-dialog__body {
  // min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>