<template>
  <div class="flex-1">
    <div class="w-100 m-auto">
      <!-- <swiper class="swiper " :options="swiperOption">
        <swiper-slide v-for="(item, index) in 6" :key=index>
          <el-image style="width: 100%; height: 100%"
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"></el-image>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> -->
      <el-carousel height="600px">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <div class="swiper">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.keyValue"
              fit="cover"
            ></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="w-75rem m-auto mt-50">
      <!-- <div class="tab_one pb-40">
        <div class="left b-shadow-b">
          <div class="m_left">
            <el-image style="width: 120%;height: 120% ;margin-left: -10%;"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"></el-image>
          </div>
        </div>
        <div class="right b-shadow-b">
          <div class="m_right">
            <div class="mt-10">
              <h2>景点名称</h2>
              <div class="text-left flex-row color-gray2 mt-10">
                <p class="w-90 m-auto">
                  这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 介绍 -->
      <!-- <tabBar title="非遗文化展" py="FEI YI WEN HUA ZHAN" :showMore="false" /> -->
      <div class="intro-part pb-40">
        <div class="img-list">
          <div style="width: 190px; height: 230px; margin: 10px">
            <el-image
              style="
                height: 100%;
                width: 100%;
                margin: 10px;
                cursor: pointer;
                border-radius: 10px;
                overflow: hidden;
              "
              v-for="(item, index) in yuData"
              :key="index"
              fit="cover"
              :src="item.url"
              @click="setPic(item)"
            ></el-image>
          </div>
        </div>
        <div class="details-part">
          <!-- <div class="" style="padding:10px">
            <el-image style="height: 100%; width: 500px;cursor: pointer;border-radius: 5px;overflow: hidden "
              fit="cover" :src="choice.url"></el-image>
          </div> -->
          <div class="details">
            <div class="intro-body">
              <h2>{{ choice.name }}</h2>
              <div class="intro" v-html="choice.intro"></div>
            </div>
            <!-- <div class="leftWord ">
              <h4>留言：</h4>
              <div class="leave-message">
                <div v-for="(item, index) in 15" :key="index"> 123213：啊色大叔的</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 下面的介绍 -->
      <!-- <div class="tab_one pb-40">
        <div class="right b-shadow-b">
          <div class="m_right">
            <div class="mt-10">
              <h2>景点名称</h2>
              <div class="text-left flex-row color-gray2 mt-10">
                <p class="w-90 m-auto">
                  这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍这是厂家的介绍
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="left b-shadow-b">
          <div class="m_left">
            <el-image style="width: 120%; height: 120% ;margin-left: -10%;"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"></el-image>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
  
<script>
// import * as swiper from 'swiper'
import mapApi from "@/api/request/map";
import { tabBar, myImage } from "@/components/index.js";
import commmonApi from "../../api/request/common";
export default {
  name: "oldHouse",
  components: { tabBar },
  data() {
    return {
      queryInfo: {
        pageSize: 10,
        pageNo: 1,
      },
      yuData: [],
      choice: {},
      banner: [],
    };
  },
  created() {
    this.getYuanList();
    this.getBanner();
  },
  methods: {
    getBanner() {
      commmonApi
        .adminKey({
          keyName: "oldHouse",
        })
        .then((res) => {
          if (res.success) {
            this.banner = res.data.list;
          }
        });
    },
    getYuanList() {
      mapApi
        .getYuanList({
          ...this.queryInfo,
        })
        .then((res) => {
          if (res.success) {
            this.yuData = res.data.list;
            this.choice = res.data.list[0];
          }
        });
    },
    setPic(e) {
      console.log(e);
      this.choice = e;
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.swiper {
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.intro-part {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .img-list {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    width: 250px;
    margin-right: 20px;
    height: 600px;
    overflow-y: scroll;
  }

  .details-part {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    flex: 1;
    background-color: #ffffff;

    .details {
      flex: 1;
      padding: 10px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .intro-body {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .intro {
        color: #000;
        margin-top: 20px;
        word-break: break-all;
        line-height: 1.8rem;
        -webkit-text-emphasis: 2rem;
        text-emphasis: 2rem;
        /* height: 230px; */
        height: 100%;
        overflow: scroll;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        text-indent: 2em;
      }

      .leftWord {
        width: calc(100% - 40px);
        height: 200px;
        border: 1px solid;
        border-radius: 20px;
        padding: 20px;
        background-color: #fff4df;
      }

      .leave-message {
        height: 100%;
        overflow-y: scroll;
      }

      .leave-message::-webkit-scrollbar {
        background-color: #ffffff00;
      }
    }
  }
}
</style>