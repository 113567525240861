<template>
  <div class="home">

    <div class="container">
      <!-- 轮播 -->
      <el-carousel :interval="4000" autoplay  :loop="true" height="700px">
        <el-carousel-item v-for="(item, index) in bannerImg" :key="index" class="swiper-item">
          <img :src="item.keyValue" @click="geBannerPage(item.keyDescribe)" />
        </el-carousel-item>
      </el-carousel>
      <!-- 主要展示内容 -->
      <div class="activity-list pt-50 w-1080px m-auto ">
        <tabBar title="文化动态" py="WEN HUA DONG TAI" @onClick="goPage('tenseMore')" />
        <div class="flex-row flex-jsb mt-20">
          <div class="w-45 relative">
            <div class="absolute flex-row flex-jsb w-100 h-30px text-left color-w z-9 b-0 l-0  bgc-b-80 ">
              <ul class=" active-ul flex-row flex-ac w-80">
                <li class=" flex flex-row flex-ac w-90" v-show="activeOne == index"
                  v-for="(item, index) in activityImgArr" :key="index">
                  <span class=" color-sp bdr-2 font-12 bdc-sp bdw-1 pv-10 ml-10 w-90px text-center">{{ item.createBy
                  }}</span>
                  <span class=" title pl-10 lh-30  text-overflow w-90">{{ item.title }}</span>
                </li>
              </ul>
              <ul class=" active-ul flex-row flex-center mr-20">
                <li class="h-5px bdr-10  ml-10 transition border-none" v-for="(item, index) in activityImgArr"
                  :class="activeOne == index ? 'bgc-warning w-20px' : 'bgc-w w-5px'" :key="index"></li>
              </ul>
            </div>
            <el-carousel :interval="40000" :loop="true" height="400px" class="over-hidden bdr-8 b-shadow-s"
              @change="changeActiveSwiper">
              <el-carousel-item v-for="item in activityImgArr" :key="item.id" class="swiper-item ">
                <img :src="item.bgcImg" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="w-50 b-shadow-s bgc-w bdr-8 ph-10">
            <ul class="flex-col">
              <!-- 最多13条数据 -->
              <li class="flex-row flex-jsb  flex-ac curp news-li" v-for="(item, index) in activityList" :key="index"
                @click="goOtherPage(item)">
                <div class="flex-row flex-1 t-hd-one ">
                  <div class="color-sp bdr-2 w-90px font-14 bdc-sp bdw-1 ">{{ item.createBy }}</div>
                  <div class="activeName ml-10 text-overflow flex-1">{{ item.title }}</div>
                </div>
                <div class="color-sp font-ss">[{{ item.createTime.split(" ")[0] }}]</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="activity-list pt-50 w-1080px m-auto ">
        <tabBar title="VR展示" py="VR ZHAN SHI" :showMore="false" />
        <div class="flex-row flex-ac">
          <div class="vr-box bdr-8 ovf-h " v-for="(item, index) in imgArr" :key="index"
            @click="goPage('vr', { id: item.id })">
            <img @click="goPage('vr', { id: item.id })" :src="item.img" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="activity-list pt-50 w-1080px m-auto ">
        <tabBar title="非遗赶大集" py="FEI YI GAN DA JI" :showMore="true" @onClick="goPage('historyList')" />
        <div class="thumb-example b-shadow bdr-4 mb-20 mt-10 h-20rem">
          <!-- swiper1 -->
          <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide class="slide-1" v-for="(item, index) in heritageData" :key="index">
              <div class="w-100 h-100 flex-row text-left ">
                <div class="w-60 h-100">
                  <el-carousel height="23rem">
                    <el-carousel-item v-for="(item2, index2) in item.imgArr.split(',')" :key="index2">
                      <div class="swiper-img-list">
                        <el-image class="w-100 h-100 bdr-6" :src="item2" fit="cover"></el-image>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class=" pl-10 word flex-1 flex-col flex-jsb">
                  <div class="mh-10rem ">
                    <p class="font-20 color-p t-hd-one ">{{ item.name }}</p>
                    <p class="mt-20 color-gray2 ">地点：{{ item.place }}</p>
                    <!-- <p class="mt-10 color-gray2">时间：{{ item.histortTime }}</p> -->
                    <div class="flex flex-row mt-10 color-gray2 flex-as ">
                      内容：<div class="w-80 font-wordBreak">{{ item.context }}</div>
                    </div>
                  </div>
                  <div>
                    <!-- <el-button type="primary" size="mini" style="float:right">查看</el-button> -->
                  </div>
                </div>

              </div>
            </swiper-slide>
          </swiper>
          <!-- swiper2 Thumbs -->
          <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
            <swiper-slide class="slide-1" style="overflow: hidden;" v-for="(item, index) in heritageData" :key="index">
              <div class="h-100 bgc-gray1">
                <el-image class="h-100 bgc-g1 bdr-4" :src="item.imgArr.split(',')[0]" fit="cover"></el-image>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- <div class="activity-list pt-50 w-1080px m-auto ">
        <tabBar title="文化资源" py="WEN HUA ZI YUAN" />
        <div class="thumb_example_no_back ">
          <div class="flex-row h-100 w-100">
            <div class="flex-col w-60">
              <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"
                class="h-100"></el-image>
              <div class="flex-row mt-10 h-40 w-100">
                <el-image class="w-100" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                  fit="cover"></el-image>
                <el-image class="w-100 ml-10"
                  src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"></el-image>
              </div>
            </div>
            <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" fit="cover"
              class="ml-10 w-40"></el-image>
          </div>
        </div>
      </div> -->
      <div class="activity-list pt-50 w-1080px m-auto ">
        <tabBar title="友情链接" py="WEN HUA ZI YUAN" :showMore="false"/>
        <div class="mt-20 ">
          <div class="flex-row flex-jsb flex-wrap w-100  pb-20 ">
            <div class="connect bdr-4  relative bdr-4 ovf-h mt-10"
              :style="{ background: item.bgcolor ? item.bgcolor : '#fff' }" v-for="(item, index) in conncetList"
              :key="index" @click="geBannerPage(item.url)">
              <div v-if="!item.img" class="h-100 w-100 absolute z-9  color-w   lh-3rem">{{ item.name }}</div>
              <el-image v-else :src="item.img" class=" h-100 w-100 z-8"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabBar, myImage } from "@/components/index.js";
import activityApi from '@/api/request/activity.js';
import cultureApi from "@/api/request/culture";
import commmonApi from "@/api/request/common";
export default {
  name: "home",
  components: { tabBar, myImage },
  data() {
    return {
      activeOne: 0,
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      imgArr: [],
      bannerImg: {},
      swiperOptionTop: {
        // loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 100,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        // loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 5,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.5,
        slideToClickedSlide: true,
      },
      activityList: [],//活动列表
      activityImgArr: [],//活动列表轮播图
      heritageData: [],//非遗文化列表
      conncetList: [
        {
          name: '国家公共文化云',
          url: 'https://www.culturedc.cn/web3.0/index.html',
          img: 'https://ossculturecloud.oss-cn-hangzhou.aliyuncs.com/bjszwhg/pc/guojiayun-logo.png',
          bgcolor: '#a162226b',
        }, {
          name: '河南省文化厅',
          url: 'https://hct.henan.gov.cn/',
          img: 'https://file.henan.gov.cn/4500000022/img/logo.png',
        }, {
          name: '河南省公共文化云',
          url: 'http://www.hawh.cn/index.html',
          img: 'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/pc_back/hnwhwlogo.jpg',
        }, {
          name: '项城市文化广电和旅游局公众号',
          url: '',
          img: 'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/pc_back/5dd3affc6b922c3b45b63d99fd570a0.png',
        }, {
          name: '文化豫约小程序',
          url: '',
          img: '',
          bgcolor: '#a162226b',
        },
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {

    // this.swiper.slideTo(3, 1000, false);
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  created() {
    // this.getActivityList();
    this.getVR();
    this.getBanner();
    this.getTrends();
    this.getHeritageList();
  },
  methods: {
    getHeritageList() {
      cultureApi.heritageList({
        pageNo: 1,
        pageSize: 20,
      }).then(res => {
        if (res.success) {
          this.heritageData = res.data.list
        }
      })
    },
    getTrends() {
      activityApi.getTrendsList({
        pageSize: 12,
        pageNo: 0
      }).then(res => {
        if (res.success) {
          let arr = res.data.list
          this.activityImgArr = arr.slice(0, 5)
          this.activityList = res.data.list;
        }
      })
    },
    // 获取顶部轮播信息
    getBanner() {
      commmonApi.adminKey({
        keyName: 'home',
        keyType: 'banner'
      }).then(res => {
        if (res.success) {
          this.bannerImg = res.data.list
        }
      })
    },
    getVR() {
      commmonApi.getVRlist({
        pageSize: 10,
        pageNo: 0,
      }).then((res) => {
        console.log("获取到的VR图片", res)
        if (res.success) {
          this.imgArr = res.data.list
        }
      })
    },
    getActivityList() {
      activityApi.getActivityList({
        pageSize: 13,
        pageNo: 0,
        isDelete: 1,
      }).then(async (res) => {
        if (res.success) {
          let arr = res.data.list
          // this.activityImgArr = arr.slice(0, 5)
        }
      })
    },
    geBannerPage(e) {
      window.location.href = e
    },
    // 
    goOtherPage(e) {
      if (e.isWeb != 1) {
        this.$confirm('此操作将跳转至其他页面', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          activityApi.updateTrends({
            id: e.id,
            readNum: e.readNum + 1,
          }).then(res => {
            if (res.success) {
              window.location.href = e.url
            }
          })
        })
      } else {
        activityApi.updateTrends({
          id: e.id,
          readNum: e.readNum + 1,
        }).then(res => {
          if (res.success) {
            this.$router.push({
              path: 'tenseMore/tenseDetails',
              query: { id: e.id }
            })
          }
        })
      }
    },
    goPage(path, query) {

      this.$router.push({
        path: path,
        query: query
      })
    },
    changeActiveSwiper(index) {
      this.activeOne = index;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.w-1080px {
  width: 1080px;
}

.home {

  // z-index: -1;
  .container {

    // height: 100vh;
    .swiper-item {
      background-color: @BackgroundG;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .activity-list {}

    .active-ul {
      list-style: none;
    }

    .activeName {
      width: 100%;
      text-align: left;
    }
  }

  .word {}
}

.thumb-example {
  height: 500px;
  background-color: #ffffff;
  padding: 1.2rem;
}

.thumb_example_no_back {
  height: 480px;
  // padding: 1.2rem;
  margin-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.swiper {
  &.gallery-top {
    height: 75%;
    width: 100%;
  }

  &.gallery-thumbs {
    height: 25%;
    box-sizing: border-box;
    padding: 20px 0;
  }

  &.gallery-thumbs .swiper-slide {
    width: 12.5%;
    height: 100%;
    opacity: 0.4;
  }

  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

.vr-box {
  height: 30rem;
  width: 8rem;
  cursor: pointer;
  transition: all .3s;
  // background-color: #fff;
  transform: rotate(25deg);
  margin-left: 2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.vr-box:hover {
  margin-top: -10px;
}

/deep/ .swiper {
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #00ff33;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}

.swiper-img-list {
  // width: 60rem;
  height: 100%;
  // height: 80rem;
  // background-color: #00ff33;
}

.font-wordBreak {
  line-height: 27px;
  text-overflow: ellipsis;
  width: 85%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.title {
  min-width: 65%;
  max-width: 65%;
}

.news-li {
  padding: 5px 10px;
}

.news-li:hover {
  box-shadow: 0 0 5px 1px #452a0e96;
}

.connect {
  width: 19%;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-carousel__indicators {
  display: none;
}
</style>

