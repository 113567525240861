<template>
  <div class="container">
    <div id="viewer" style="width: 100vw; height: 100vh;" ref="psvdbg"></div>
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer'
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers'
import { GalleryPlugin } from 'photo-sphere-viewer/dist/plugins/gallery'
import 'photo-sphere-viewer/dist/plugins/markers.css';
import 'photo-sphere-viewer/dist/plugins/gallery.css';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import { GyroscopePlugin } from 'photo-sphere-viewer/dist/plugins/gyroscope'
import commmonApi from '@/api/request/common';
export default {
  data() {
    return {
      imgurl: '',
      routerQuery: {},
      markersPlugin: undefined,
      id: '',
    }
  },
  mounted() {
    this.init();

  },
  created() {
    let route = this.$route.query;
    this.routerQuery = route;
    this.imgurl = route.imgPath;
    this.id = route.id
    // this.getList();
  },
  methods: {
    getList(e) {
      commmonApi.getVrRoomList({
        buildId: e
      }).then(res => {
        if (res.success) {
          const gallery = this.viewer.getPlugin(GalleryPlugin);
          let arr = [];
          if (res.data.list) {
            res.data.list.map(item => {
              arr.push({
                id: item.id,
                panorama: item.panorama || 'https://photo-sphere-viewer-data.netlify.app/assets/sphere.jpg  ',
                thumbnail: item.thumbnail,
                name: item.roomName,
                options: {
                  caption: item.caption,
                },
              })
            })
       
          gallery.setItems(arr);
             }
        }
      })
    },
    init() {
      this.viewer = new Viewer({
        // container: document.querySelector('#viewer'),
        container: this.$refs.psvdbg,
        panorama: this.imgurl,
        loadingTxt: "正在加载...",
        loadingImg: this.imgurl,
        // gyroscope: true,
        size: {
          width: '100vw',
          height: '100vh',
        },
        // navbar: [
        //   'autorotate',
        //   'zoom',
        //   // 'gyroscope',
        //   'markers',
        //   'caption',
        //   'fullscreen'
        // ],
        plugins: [
          [GyroscopePlugin],//增加了陀螺仪
          [GalleryPlugin, {
            visibleOnLoad: true,
            // hideOnClick: true,
            // thumbnailSize: { width: 130, height: 100 },
          }],
        ],
      });
      this.getList(this.id)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  flex: 1;

  .vr_body {
    height: 100vh;
    width: 100vw;
  }
}
</style>