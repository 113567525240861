import Vue from 'vue'
import Vuex from 'vuex'
import ApiContainer from "../api/url"
import user from './modules/user'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  modules: {
    user,
  },
  mutations: {
  }
})
export default store;