<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <!-- 顶部标题 -->
      <div class="w-100 ml-5 h-20rem bgc-w mt-20 flex-row flex-ac bdr-4 b-shadow">
        <el-image class="h-90 w-30rem ml-20 bdr-4" :src="cultureInfo.img" fit="cover"></el-image>
        <div class="h-90 ml-20  flex-col font-wl flex-1 text-left">
          <div class="color-p font-1_5rem ">{{ cultureInfo.name }}</div>
          <div class="color-gray2 font-0_8rem mt-40">工作人员数：{{ cultureInfo.members }}</div>
          <div class="color-gray2 font-0_8rem mt-10">联系方式:{{ cultureInfo.tel }}</div>
          <div class="color-gray2 font-0_8rem mt-10">开放时间：{{ cultureInfo.openTime }}-{{ cultureInfo.openTime }}</div>
          <div class="color-gray2 font-0_8rem mt-10 pr-30 t-hd-four flex-1">简介：<span>{{ cultureInfo.intro }}</span></div>
          <!--  -->
          <div class="flex-row flex-je mr-30">
            <div class="mt-20">
              <el-button icon="el-icon-thumb" circle type="primary" plain @click="like(cultureInfo)"> </el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ cultureInfo.likeNum }}</span>
            </div>
            <div class="mt-20 ml-20">
              <el-button icon="el-icon-share" circle type="primary" plain @click="sharePage(cultureInfo)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 下面 -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50">
        <div class="w-65  ph-10 bgc-w mh-50h  ">
          <mySection title="场馆介绍" />
          <div class="content">
            <div class="" v-if="cultureInfo.content" v-html="cultureInfo.content"> </div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
          <!-- <el-divider></el-divider> -->
          <!-- <mySection title="可预约内容" />
          <div class="">
            <div v-if="cultureInfo.isBook == 1">
            </div>
            <el-empty image="" v-else-if="cultureInfo.isBook == 0" description="该房间暂时不可预约"></el-empty>
            <el-empty image="" v-else description="暂无可预约房间"></el-empty>
          </div> -->
        </div>
        <div class="w-30 bgc-w ph-10 mh-50h">
          <mySection title="热门场馆" />
          <div class="">
            <div v-if="pushList.length">
              <div v-for="(item, index) in  pushList " :key="index">
                <div class="pushItem" @click="goPushItem(item)">
                  <div class="tags">
                    <el-tag type="warning" effect="plain"> {{ item.isStree==1?'街道文化站':'乡镇文化站' }} </el-tag>
                  </div>
                  <el-image style="width: 100%; height: 10em" :src="item.img" fit="cover"></el-image>
                  <div class="pushItemName">
                    <div>{{ item.name }}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myRadio, myBreadCrumb, mySection } from "@/components/index.js";
import cultureApi from "@/api/request/culture.js";
import commmonApi from '../../api/request/common';
import share from "@/util/share";
export default {
  name: "venueDetails",
  components: { myRadio, myBreadCrumb, mySection },
  data() {
    return {
      cultureInfo: {},
      pushList:[],
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      // 顶部的目录
      menuList: [
        {
          path: "/Activity",
          name: "社区文化站",
        },
        {
          path: "/ActivityList",
          name: "文化站详情",
        },
      ],
    };
  },
  created() {
    this.getVeneueInfo();
    this.getPushList();
  },
  methods: {
    // 获取推荐列表
    getPushList() {
      cultureApi.cultureList({
        push: '1',
        pageSize:3,
        pageNo:1,
      }).then(res => {
        if (res.success) {
          this.pushList = res.data.list;
        }
      })
    },
    sharePage(e) {
      console.log(share)
      share.share2QQ(
        {
          url: 'http://yuyitang.xyz:3333',
          desc: e.intro,
          title: e.name,
        }
      )
    },
    // 点赞
    like(e) {
      commmonApi.likeRecord({
        partName: '社区文化站',
        partId: this.cultureInfo.id,
        isLike: '1',
      }).then(res => {
        if (res.success) {
          cultureApi.updateActivity({
            likeTimes: this.cultureInfo.likeNum + 1,
            id: this.$route.query.id
          }).then(res2 => {
            if (res2.success) {
              this.$message.success("点赞成功")
              this.cultureInfo.likeNum = this.cultureInfo.likeNum + 1;
            }
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    getVeneueInfo() {
      cultureApi.getCultureInfoById({
        id: this.$route.query.id
      }).then(res => {
        if (res.success) {
          this.cultureInfo = res.data;
        }
      })
    },
  },

};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.content {
  min-height: 20rem;
  overflow-y: scroll;
  max-height: 25rem;
padding: 0 20px;
}

.activity-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}
.pushItem {
  width: 94%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  text-align: left;
}

.tags {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 10;
}

.pushItemName {
  height: 3em;
  border-bottom: 1px solid #efefef;
}
</style>