<template>
  <div>
    <div style="height:60px;width:100%"></div>
    <div class="nav">
      <div class="logo">
        <!-- <h3>项城文旅</h3> -->
        <el-image style="height: 90%;" :src="require('../../assets/common/vr/banner.png')" />
      </div>
      <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#452A0E" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="/" path="/">首页</el-menu-item>
        <el-menu-item index="activitylist" path="/activitylist">文化活动</el-menu-item>
        <el-menu-item index="Venue" path="/Venue">文化场馆</el-menu-item>
        <el-menu-item index="GreatMap" path="/GreatMap">特色地图</el-menu-item>
        <el-menu-item index="Live" path="/Live">魅力直播</el-menu-item>
        <el-menu-item index="townCultural" path="/townCultural">文化服务中心</el-menu-item>
        <!-- <el-submenu index="6">
          <template slot="title">文化服务中心</template>
          <el-menu-item index="CulturalStation" path="/CulturalStation">村（社区）文化服务中心</el-menu-item>
          <el-menu-item index="townCultural" path="/townCultural">镇（街道）文化站</el-menu-item>
        </el-submenu> -->
        <el-submenu index="7">
          <template slot="title">艺术团队</template>
          <el-menu-item index="YuTeam" path="/YuTeam">豫剧团</el-menu-item>
          <el-menu-item index="OtherTeam" path="/OtherTeam">其他团队</el-menu-item>
        </el-submenu>
        <el-submenu index="8">
          <template slot="title">魅力景区</template>
          <el-menu-item index="visitPlan" path="/visitPlan">旅行攻略</el-menu-item>
          <el-menu-item index="ScenicSpot" path="/ScenicSpot">景区介绍</el-menu-item>
          <el-menu-item index="oldHouse" path="/oldHouse">袁世凯故居</el-menu-item>
        </el-submenu>
        <el-submenu index="9">
          <template slot="title">文化执法</template>
          <el-menu-item index="Enforcement" path="/Enforcement">网吧</el-menu-item>
          <el-menu-item index="ktv" path="/ktv">KTV</el-menu-item>
          <el-menu-item index="train" path="/train">培训机构</el-menu-item>
        </el-submenu>
        <el-menu-item index="DigitalSource" path="/DigitalSource">数字资源</el-menu-item>
        <el-menu-item index="DigitalScreen" path="/DigitalScreen">数字大屏</el-menu-item>

      </el-menu>
      <div class="userInfo" @click="goPage('/user')">
        <el-avatar :size="35"
          :src="userInfo && userInfo.wx_head || 'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/user.png'"></el-avatar>
        <span class="username" :style="{ 'color': activeIndex2 == -1 ? '#ffd04b' : '#fff' }">{{
          userInfo && userInfo.wx_name || '用户未登录' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myNav",
  data() {
    return {
      activeIndex2: "1",
      userInfo: '',//用户信息
      tabbarInfo: [],
      circleUrl:
        " https://bpic.51yuansu.com/backgd/cover/00/59/28/62c2959ccf1ce_800.jpg?x-oss-process=image/resize,w_260,m_lfit/sharpen,100",
    };
  },
  mounted() {
    this.getVuexData();
  },

  created() {
    let menu = window.localStorage.getItem("preventMenu");
    this.activeIndex2 = menu || '/'
    this.$router.replace(menu || '/');
  },

  beforeRouteLeave(to, from, next) {
    // 实现对当前页面的数据处理
    alert("要关闭弹框了")
    next();
  },
  methods: {
    getVuexData() {
      let data = this.$store.state.user.userInfo;
      console.log(data);
      let userInfo = localStorage.getItem("userInfo");
      this.userInfo = JSON.parse(userInfo)
    },
    goPage(e) {
      this.$router.push(e);
      this.activeIndex2 = -1;
    },
    handleSelect(index, indexArr, e) {

      this.$router.replace(e.$attrs.path);
      this.activeIndex2 = index;
      localStorage.setItem("preventMenu", e.$attrs.path.split("/")[1])
      this.$emit("change", e, index);
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  background: #452a0e;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  min-width: 1280px;

  .logo {
    flex: 1;
    height: 100%;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      color: #fff;
      font-size: 28px;
      height: 100%;
      line-height: 60px;
    }
  }
}

.userInfo {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: #fff;
    margin-left: 15px;
  }
}

.username {
  width: 5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
::v-deep .el-menu-item{
  padding: 0 10px;
}
::v-deep .el-submenu__title{
  padding: 0 10px;
}

</style>