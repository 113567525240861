<!-- 文化动态更多页面 -->
<template>
  <div class="container">
    <div class="w-75rem m-auto">
      <!-- <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div> -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50">
        <div class="w-100 ph-10 bgc-w mh-50h  ">
          <ul class="list-ul" v-if="dataList.length">
            <li class="list-li animate__animated animate__backInUp" v-for="(item, index) in dataList" :key="index"
              @click="openPic(item)">
              <div class="li-item">
                <el-image :src="item.imgArr && item.imgArr.split(',')[0]" fit="cover "
                  style="width: 240px; height: 150px">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>

                <div class="word">
                  <div class="name t-hd-two">{{ item.name }}</div>
                  <div class="details t-hd-two">{{ item.context }}</div>
                  <div class="info">
                    <div class="secondeInfo">所在地：{{ item.place }}</div>
                    <!-- <div class="secondeInfo">{{ item.readNum }}次阅读</div> -->
                    <div class="secondeInfo">{{ item.createTime }}</div>
                  </div>
                </div>
                <div class="icon"></div>
              </div>
            </li>
          </ul>
          <div class="empty" v-else>
            <el-empty description="暂无内容"></el-empty>
          </div>
          <div class="mt-20">
            <el-pagination background layout="prev, pager, next" :total="searchParams.total" 
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-backtop target=".container"></el-backtop>
    <el-dialog :title="title" :visible.sync="visiable" width="500px" height="500px">
      <div style="height:30rem">
        <el-carousel height="30rem">
          <el-carousel-item v-for="(item, index) in preview" :key="index">
            <el-image fit="cover" style=" height: 100%" :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  myRadio,
  myBreadCrumb,
  activityItem,
  mySection,
} from "@/components/index.js";
import cultureApi from '@/api/request/culture.js';
export default {
  components: { mySection, myBreadCrumb },
  name: "tenseMore",
  data() {
    return {
      menuList: [
        {
          path: "/Activity",
          name: "文化活动",
        },
        {
          // path: "/Activity",
          name: "活动列表",
        },
      ],
      dataList: [],
      preview: [],
      visiable: false,
      title: '',
      preList: {},
      searchParams: {
        pageSize: 10,
        pageNo: 0,
        total: 0,
      }
    }
  },
  mounted() {
    this.getTrends();
  },


  methods: {
    // 打开预览图片
    openPic(e) {
      console.log("打开预览图")
      this.preview = e.imgArr.split(',');
      this.visiable = true;
      this.title = e.name
      console.log(this.preview)
    },
    handleCurrentChange(e) {
      this.searchParams.pageNo = e;
      this.getTrends();
      console.log(e);
    },
    // 获取列表
    async getTrends() {
      this.dataList = [];
      cultureApi.heritageList({
        pageSize: this.searchParams.pageSize,
        pageNo: this.searchParams.pageNo,
      }).then(res => {
        if (res.success) {
          this.dataList = res.data.list;
          this.searchParams.total = res.data.searchParams.total;
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.container {
  margin-top: 86px;

  .list-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    .list-li {
      width: calc(100% - 40px);
      border-bottom: 1px solid #efefef;
      padding: 20px;
    }

    .li-item {
      display: flex;
      flex-direction: row;
      text-align: left;
      position: relative;
      align-content: center;

      .name {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #000;
        margin-left: 20px;
        width: 35em;
        overflow: hidden;
        letter-spacing: 2px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        cursor: pointer;
      }

      .word {
        display: flex;
        flex-direction: column;
        // justify-content: center;
      }

      .details {
        margin-top: 10px;
        line-height: 24px;
        margin-left: 20px;
        font-size: 18px;
        width: 45em;
        color: #999999;
      }

      .info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        line-height: 22px;
        margin-left: 20px;
        position: absolute;
        bottom: 0px;

        .secondeInfo {
          font-size: 15px;
          color: #999;
          padding-right: 24px;
        }
      }
    }
  }
}
</style>