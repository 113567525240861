<template>
  <div class="yuItem bdw-1 bdr-4 bdc-bs mt-10 flex-row flex-ac relative" @click="callBack()">
    <template v-if="isMore">
      <el-image style="width: 100%; height:100%"
        :src="img" fit="cover"></el-image>
      <div class="shadow w-100 h-100 absolute">
        <span class="more">查看更多</span>
      </div>
    </template>
    <template v-else>
      <div>
        <el-image style="width: 10rem; height:7rem;margin-left:0.5rem"
          :src="img" fit="cover"></el-image>
      </div>
      <div class="ml-10 text-left">
        <p class="color-sp">《{{ name }}》</p>
        <p class="details font-12 t-hd-four w-80 ml-10 mt-10 h-4rem">{{ intro }}</p>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    isMore: {
      default: false,
      type: Boolean,
    },
    img:{
      type: String,
      default: '',
    }
  },
  methods:{
    callBack(){
      this.$emit("onClick")
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.yuItem {
  height: 8rem;
  width: 21rem;
  cursor: pointer;

  .details {
    margin-top: 0.4rem;
  }

  .shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0000004f;

    .more {
      color: #fff;
      font-size: 2rem;

    }
  }
}
</style>