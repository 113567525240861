
import { get, post, postImg } from "@/util/http"
import ApiContainer from "../url"
const mapApi = {
  mapList: params => get(ApiContainer.mapList, params),  //地图地点列表
  getTypeDetailsById: params => get(ApiContainer.getTypeDetailsById, params),//创建圈子
  getTypeListById: params => get(ApiContainer.getTypeListById, params),//获取答题列表历史记录
  getQuestion: params => get(ApiContainer.getQuestion, params, params),//获取题目
  updateMap: params => post(ApiContainer.updateMap, params),//修改地图信息
  getYuanList:params=>get(ApiContainer.getYuanList,params),//获取袁世凯故居数据
  getMapImagelist:params=>get(ApiContainer.getMapImagelist,params),//获取图片列表
}
export default mapApi