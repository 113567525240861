<template>
  <div class="videoBox">
    <video :src="teamDetails.videoUrl" class="video" laysinline autoplay="false" controls controlslist="nodownload"/>
  </div>
</template>

<script>
import teamApi from "@/api/request/team"
export default {
  name: 'otherTeamDetail',
  data() {
    return {
      otherTeamId: '',
      videoUrl: '',
      teamDetails:{},
    }
  },
  created() { },
  mounted() {
    let query = this.$route.query;
    if (query) {
      this.otherTeamId = query.id;
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      teamApi.getTeamInfoById({
        id: this.otherTeamId
      }).then(res => {
        if(res.success){
          this.teamDetails = res.data;
        }
      })
    },
  }
}
</script>

<style lang="less" scope>
.videoBox {
  flex: 1;
  // background-color: red;
  width: 80%;
  margin: 0 auto;

  .video {
    height: 100%;
    width: 100%;
    background-color: #000000;
  }
}
video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
}
</style>