<template>
  <div class="bgc-p pb-20 mt-10 bdr-4">
    <h1 class="color-w">{{ title }}</h1>
    <div id="line" :style="{ width: '30rem', height: '15rem', padding: '0 1rem' }"></div>
  </div>
</template>

<script>
export default {
  name: "category",
  props: {
    title: {
      default: "柱状图"
    },
    cutData: undefined,
  },
  data() {
    return {
      msg: "fafa",
      myChart: undefined,
    };
  },
  created() {

  },
  mounted() {
    this.drawLine();
    if (this.cutData.length) {
      let xRays = [];
      let originData = [];
      this.cutData.map((item, index) => {
        xRays[index] = item.venueName;
        originData[index] = item.num
      })
      var option = this.myChart.getOption();
      option.series[0].data = originData;
      option.xAxis[0].data = xRays;
      this.myChart.setOption(option);
    }
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById('line'));
      // 绘制图表
      this.myChart.setOption({
        xAxis: [{
          type: 'category',
          data: []
        }],
        
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "5%",
          containLabel: true,
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            color: "#fff",
            type: "shadow",
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ]
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>