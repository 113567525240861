/**
 * 获取调用函数时的时间
 * @param {*} format 获取时间格式，如YYYY-MM-DD hh:mm:ss
 * @returns 
 */
function getCurrentTime(format = 'YYYY-MM-DD hh:mm:ss') {
  var now = new Date();
  var year = now.getFullYear();
  var month = String(now.getMonth() + 1).padStart(2, '0');
  var day = String(now.getDate()).padStart(2, '0');
  var hours = String(now.getHours()).padStart(2, '0');
  var minutes = String(now.getMinutes()).padStart(2, '0');
  var seconds = String(now.getSeconds()).padStart(2, '0');

  var formattedTime = format
    .replace('YYYY', year)
    .replace('MM', month)
    .replace('DD', day)
    .replace('hh', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);

  return formattedTime;
}


export {
  getCurrentTime
}