<template>
  <div class="container">
    <div id="viewer" style="width: 100vw; height: calc(100vh - 60px);" ref="psvdbg"></div>
  </div>
</template>
<script>
import { Viewer } from 'photo-sphere-viewer'
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers'
import { GalleryPlugin } from 'photo-sphere-viewer/dist/plugins/gallery'
// import { AutorotatePlugin } from 'photo-sphere-viewer/dist/plugins/autorotate'
import 'photo-sphere-viewer/dist/plugins/markers.css';
import 'photo-sphere-viewer/dist/plugins/gallery.css';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import commmonApi from '@/api/request/common';
const animatedValues = {
  pitch: { start: -Math.PI / 2, end: 0.2 },
  yaw: { start: Math.PI, end: 0 },
  zoom: { start: 0, end: 50 },
  fisheye: { start: 2, end: 0 },
};
export default {
  data() {
    return {
      viewer: '',
      VRid: '',
      imgurl:'',
    }
  },
  mounted() {
    let query = this.$route.query;
    console.log(123, query)
    if (query && query.id) {
      this.VRid = query.id
      this.getData(query.id);//获取最外层建筑物的vr信息

    }

  },
  methods: {
    getList(e) {
      commmonApi.getVrRoomList({
        buildId: e,
        pageSize:20,
      }).then(res => {
        if (res.success) {
          const gallery = this.viewer.getPlugin(GalleryPlugin);
          let arr = [];
          res.data.list.map(item => {
            arr.push({
              id: item.id,
              panorama: item.panorama || 'https://photo-sphere-viewer-data.netlify.app/assets/sphere.jpg  ',
              thumbnail: item.thumbnail,
              name: item.roomName,
              options: {
                caption: item.caption,
              },
            })
          })
          gallery.setItems(arr);
        }
      })
    },
    getData(id) {
      commmonApi.getVRlist({
        id: id
      }).then(res => {
        if (res.success) {
          this.imgurl = res.data.list[0].imgUrl;
          this.init();
          this.getList(id);//获取vr列表
        }
      })
    },
    init() {
      console.log("绘画VR")
      this.viewer = new Viewer({
        container: this.$refs.psvdbg,
        panorama: this.imgurl,
        loadingTxt: "正在加载...",
        loadingImg: this.imgurl,
        // navbar:[
        //   zoomOut,
        //   zoomIn,
        // ],
        // defaultPitch: animatedValues.pitch.start,
        // defaultYaw: animatedValues.yaw.start,
        // defaultZoomLvl: animatedValues.zoom.start,
        // fisheye: animatedValues.fisheye.start,
        lang: {
          zoom: 'Zoom',
          zoomOut: '缩小',
          zoomIn: '放大',
          move: '移动视角',
          moveDown: '向下',
          moveLeft: '向左',
          moveRight: '向右',
          // download: '下载资源',
          gallery:'其他场景',
          fullscreen: '全屏',
          menu: '菜单',
          close: '关闭',
          twoFingers: '双手指操作',
          ctrlZoom: '按下 ctrl 和鼠标滚轮进行放大缩小',
          loadError: '资源错误',
        },
        size: {
          width: '100%',
          height: 'calc (100vh - 60px) ',
        },
        plugins: [
          [GalleryPlugin, {
            visibleOnLoad: true,
            // hideOnClick: true,
            // thumbnailSize: { width: 130, height: 100 },
          }],
        ],
      });
    }
  }
}
</script>
<style scoped lang="less">
.container {
  flex: 1;

  .vr_body {
    height: calc(100vh - 60px);
    width: 100vw;
  }
}
</style>