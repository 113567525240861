<template>
  <div class="activityItem b-shadow bgc-w relative ovf-h">
    <div class="ovf-h">
      <el-image class="w-100 h-13rem activityImg" :src="activiytPosters" fit="cover"></el-image>
    </div>
    <div class="font-18 color-p w-90 t-hd-two h-60px m-auto activeName">{{ activityName }}</div>
    <div class="w-90 m-auto font-14">

      <p class=" text-left">活动时间：
        <span class="color-gray2" v-if="startTime">{{ `${startTime || ''} 到 ${endTime || ''}` }}</span>
        <span class="color-gray2" v-else>任意</span>
      </p>

      <p class=" text-left" v-if="activityType == '1'"> 活动地点：
        <span class="color-gray2">{{ activityLocation }}</span>
      </p>
      <p class=" text-left" v-else> 举办单位：
        <span class="color-gray2">{{ holder }}</span>
      </p>
      <div class=" text-left flex-row "> 活动状态：
        <p class="color-gray2" v-if="time<startTime"> 未开始 </p>
        <p class="color-gray2" v-else-if="time>endTime"> 已结束 </p>
        <p class="color-gray2" v-else>进行中</p>
        
      </div>
    </div>
    <div class="w-100 text-right absolute  b-10">
      <el-button type="primary" size="mini" class="myBtn mr-20" @click="callBack">参加活动</el-button>
    </div>
  </div>
</template>

<script>
import { getCurrentTime } from "@/util/time"
export default {
  name: "activityItem",
  props: {
    activityName: {//活动名称
      default: "活动名称",
      type: String,
    },
    activityType: {//活动类型，0-线上，1-线下
      default: '0',
      type: String,
    },
    activiytPosters: {//背景大图
      default: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      type: String,
    },
    activityLocation: {//活动举办地点
      default: '',
      type: String,
    },
    startTime: {//活动开始时间
      type: String,
    },
    endTime: {//活动截止时间
      type: String
    },
    state: {
      default: 0,//活动状态。0-未开始，1-进行中,2-已经结束
      type: Number,
    },
    holder: {//举办地点
      type: String,
    }
  },
  data() {
    return {
      time:'',
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      activityState: ['未开始', '进行中', '已结束'],
    };
  },
  mounted(){
    this.time = getCurrentTime('YYYY-MM-DD');
  },
  methods: {
    callBack() {
      this.$emit("onClick")
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.activityItem {
  width: 280px;
  height: 350px;
  border-radius: 10px;
  cursor: pointer;
}
.activityImg,
.activityItem {
  transition: all .3s;
}
.activeName{
  height: 2.8em;
  text-align: left;
}
.activityItem:hover {
  box-shadow: 3px 3px 8px 0px #3d3d3d;

}

.activityItem:hover .activityImg {
  transform: scale(1.1);
}
</style>