<template>
  <div class="otherTeam-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
      <div class="mt-30 flex-row lex-jsb flex-wrap pb-30 " v-if="teamList.length">
        <otherTeamItem v-for="(item, index) in  teamList " :key="index" :name="item.name" :detais="item.detais"
          :leader="item.leader" :leaderTel="item.leaderTel" :place="item.place" :time="item.time" :img="item.img"
          @onClick="goPage('otherTeamDetail', { id: item.id })" />
      </div>
      <div class="mt-30 bgc-w pb-30 " v-else>
        <el-empty description="暂无团队"
          image="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/miss.png"></el-empty>
      </div>
      <div class=" mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { myRadio, myBreadCrumb, otherTeam } from "@/components/index.js";
import commmonApi from "@/api/request/common";
import teamApi from "@/api/request/team";
export default {
  name: "otherTeam",
  components: { myRadio, myBreadCrumb, otherTeamItem: otherTeam },
  data() {
    return {
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      // 顶部的目录
      menuList: [
        {
          // path: "/Activity",
          name: "艺术团队",
        },
        {
          // path: "/Activity",
          name: "其他团队",
        },
      ],
      queryInfo: {
        pageNo: 0,
        pageSize: 8,
      },
      searchParams: {},
      total: 0,
      selection: [],
      teamList: [],
    };
  },
  created() {
    this.getClass();
    this.getList();
  },
  methods: {
    getList(params = {}) {
      teamApi.teamList({
        isYu: 0,
        ...params,
        ...this.searchParams,
        ...this.queryInfo,
      }).then(res => {
        if (res.success) {
          this.total = res.data.searchParams.total
          this.teamList = res.data.list;

        }
      })
    },
    changeType(e) {
      this.searchParams[e.type.value] = e.data
      this.getList(this.searchParams)
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getClass() {
      commmonApi.adminKey({
        keyName: 'normal_team',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map((item2, index2) => {
                arr[index2] = {
                  label: item2.keyDescribe,
                  value: item2.keyValue
                }
              })
              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    goPage(e, query) {
      if (query) {
        this.$router.push({ path: e, query: query })
      } else {
        this.$router.push(e);

      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.otherTeam-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}
</style>