<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
      <div class="mt-30 flex-row  flex-wrap pb-30" v-if="activityList.length != 0">
        <activityItem class="mt-20 mr-10 ml-10" v-for=" (item, index) of activityList" :activityType="item.activityType"
          :activityLocation="item.activityLocation" :activiytPosters="item.activiytPosters"
          :activityName="item.activityName" :startTime="item.startTime ? item.startTime.split(' ')[0] : ''"
          :endTime="item.endTime.split(' ')[0]" :state="item.state" :holder="item.holder" :key="index"
          @onClick="goPage('/activityDetails', { id: item.id })" />
      </div>
      <div class="mt-30 bgc-w pb-30 " v-else>
        <el-empty description="暂无活动"
          image="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/miss.png"></el-empty>
      </div>
      <div class="mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { myRadio, myBreadCrumb, activityItem } from "@/components/index.js";
import commmonApi from "@/api/request/common";
import activityApi from "@/api/request/activity";
export default {
  name: "activitylist",
  components: { myRadio, myBreadCrumb, activityItem },
  data() {
    return {
      queryInfo: {
        pageNo: 1,
        pageSize: 8,
      },
      total: 0,
      searchParams: {},
      // 顶部的目录
      menuList: [
        {
          path: "/Activity",
          name: "文化活动",
        },
        {
          // path: "/Activity",
          name: "活动列表",
        },
      ],
      activityList: [],
      selection: [],
    };
  },
  created() {
    this.getList();
    this.getClass();
  },
  methods: {
    changeType(e) {
      this.queryInfo = {
        pageNo: 1,
        pageSize: 8,
      }
      this.searchParams[e.type.value] = e.data
      this.getList(this.searchParams)
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getList(params = {}) {
      activityApi.getActivityList({
        orderBy: 'startTime',
        ...params,
        ...this.searchParams,
        ...this.queryInfo,
        isDelete: 1,//上架的产品
      }).then(res => {
        if (res.success) {
          this.total = res.data.searchParams.total
          this.activityList = res.data.list
        }
      })
    },
    // 获取顶部的选项
    getClass() {
      commmonApi.adminKey({
        keyName: 'activity_list',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map(async (item2, index2) => {
                arr.push({
                  label: item2.keyDescribe,
                  value: item2.keyValue
                })
              })

              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    goPage(e, query) {
      this.$router.push({
        path: e,
        query
      });
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.activity-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}
</style>