<template>
  <div class="flex-1 w-100 h-100 bg-w">
    <div class="flex-row flex-jsb  w-75rem m-auto mt-50">
      <!-- <el-image class="w-45 h-8rem" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        fit="cover"></el-image>
      <el-image class="w-45 h-8rem" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        fit="cover"></el-image> -->
    </div>
    <div class="w-100 m-auto mt-30 mb-50 bgc-w pt-10 pb-30">
      <tabBar class=" m-auto mt-10 w-75rem" title="线上活动" py="XIAN SHANG HUO DONG" @onClick="goPage('/activitylist')" />
      <div class="flex-row flex-jsa m-auto mt-20 w-75rem">
        <activityItem class="mt-20 mr-10 ml-10" v-for=" (item, index) of activityList" :activityType="item.activityType"
          :activityLocation="item.activityLocation" :activiytPosters="item.activiytPosters"
          :activityName="item.activityName" :startTime="item.startTime" :endTime="item.endTime" :state="item.state"
          :holder="item.holder" :key="index" @onClick="goPage('/activityDetails', { id: item.id, activityType: 0 })" />
      </div>
      <tabBar class=" m-auto mt-20 w-75rem " title="线下活动" py="XIAN XIA HUO DONG" @onClick="goPage('/activitylist')" />
      <div class="flex-row flex-jsa m-auto mt-20 w-75rem">
        <activityItem class="mt-20 mr-10 ml-10" v-for=" (item, index) of activityList1" :activityType="item.activityType"
          :activityLocation="item.activityLocation" :activiytPosters="item.activiytPosters"
          :activityName="item.activityName" :startTime="item.startTime" :endTime="item.endTime" :state="item.state"
          :holder="item.holder" :key="index" @onClick="goPage('/activityDetails', { id: item.id, activityType: 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import { tabBar, myBreadCrumb, activityItem } from "@/components/index.js";
import activityApi from '@/api/request/activity.js'
export default {
  name: "activity",
  components: { myBreadCrumb, tabBar, activityItem },
  data() {
    return {
      activityList: [],
      activityList1: [],
    };
  },
  created() {
    this.getActivity(0);
    this.getActivity(1);
  },
  methods: {
    getActivity(type = 0) {
      activityApi.getActivityList({
        pageNo: 0,
        pageSize: 4,
        isDelete: 1,//上架的产品
        activityType: type
      }).then(res => {
        if (res.success) {
          if (type == 0) {
            this.activityList = res.data.list

          } else {
            this.activityList1 = res.data.list
          }
        }
      })
    },
    goPage(e, query) {
      this.$router.push({
        path: e,
        query
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>