<template>
  <div class="venueItem b-shadow bgc-w bdr-4 pb-10 hover-move">
    <div class="w-100 h-11rem ovf-h">
      <el-button class="vrRoom" type="info" icon="el-icon-school" circle v-if="vrId" @click="checkVr">
      </el-button>
      <el-image class="img " :src="headPic" fit="cover"></el-image>
    </div>
    <div class="font-sb-wb color-p w-90 ml-10 mt-10 t-hd-one ">{{ venueName }}</div>
    <p class="font-sn flex-row font-14 text-left ml-10 mt-10 mr-10 flex-ac flex-jsb ">
      <span class="color-gray2 t-hd-two"> 联系人：{{ contactMan }}</span>
    </p>
    <p class=" font-sn flex-row font-14 text-left ml-10 flex-ac flex-jsb ">
      <span class="color-gray2 text-overflow">联系方式：{{ contactTel }}</span>
    </p>
    <p class=" font-sn flex-row font-14 text-left ml-10 flex-ac flex-jsb ">
      <span class="color-gray2 text-overflow">地址：{{ location }}</span>
    </p>
    <p class="font-sn flex-row font-14 text-left ml-10 flex-ac flex-jsb ">
      <span class="color-gray2">开放时间：{{ openTime }}</span>
    </p>
    <!-- <el-button type="primary" size="mini" @click="checkVr">查看VR</el-button> -->

    <div class="myBtn">
      <div class="trangle"></div>

      <el-button type="primary" size="mini" @click="callBack">查看场馆</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "activityItem",
  props: {
    venueName: {
      type: String,
      default: "场景名称"
    },
    location: {
      type: String,
      default: '',
    },
    contactMan: {
      type: String,
      default: '',//联系人
    },
    contactTel: {
      type: String,
      default: '',//联系方式
    },
    openTime: {
      type: String,
      default: '',//开放时间
    },
    headPic: {
      type: String,
      default: '',
    },
    vrId:{
      type:String,
      default:''
    }

  },

  data() {
    return {};
  },
  methods: {
    callBack() {
      this.$emit("onClick")
    },
    checkVr() {
      this.$emit("onCheckVr")

    }
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.venueItem {
  //   width: 25%;
  position: relative;
  width: 18rem;
  // height: 300px;
  overflow: hidden;
  margin-right: 0.8%;
  height: 313px;
  cursor: pointer;
}

// .venueItem:hover .myBtn {
//   bottom: 0px;
// }

.trangle {
  width: 0;
  height: 0;
  margin-bottom: -2px;
  border-width: 0 10px 10px;

  border-style: solid;
  border-color: transparent transparent #452a0e;
}

.myBtn {
  // float:right;
  // margin-right: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .4s;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.vrRoom {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 20;
}
</style>
