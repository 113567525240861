import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Tabber,
  Home, tenseMore, tenseDetails, historyList,
  Activity, Activitylist, ActivityDetails,
  Venue, VenueList, venueDetails,
  GreatMap,
  Live,
  CulturalStation, townCultural, cultureDetail,
  YuTeam, yuDetails, OtherTeam,otherTeamDetail,
  ScenicSpot,
  SpotDetails,
  visitPlan,
  oldHouse,
  Enforcement, train, ktv,
  DigitalSource,
  DigitalScreen,
  user,
  login, regiest,
  vr,
  about, visitPlanDetails,//
  mobileVR, mobileVideo
} from "../views/index.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Tabber',
    component: Tabber,
    children: [
      {
        path: '/',//首页，
        name: 'Home',
        component: Home,
      }, {
        path: '/Activity',//活动
        name: 'Activity',
        component: Activity,
      }, {
        path: '/Activitylist',//活动分页查询
        name: 'Activitylist',
        component: Activitylist,
      }, {
        path: '/ActivityDetails',//活动详情页
        name: 'ActivityDetails',
        component: ActivityDetails,
      }, {
        path: '/Venue',//场馆
        name: 'Venue',
        component: Venue,
      }, {
        path: '/VenueList',//场馆
        name: 'VenueList',
        component: VenueList,
      }, {
        path: '/venueDetails',//场馆
        name: 'venueDetails',
        component: venueDetails,
      }, {
        path: '/GreatMap',//地图
        name: 'GreatMap',
        component: GreatMap,
      }, {
        path: '/Live',//直播
        name: 'Live',
        component: Live,
      }, {
        path: '/CulturalStation',//社区文化站
        name: 'CulturalStation',
        component: CulturalStation,
      }, {
        path: '/townCultural',
        name: 'townCultural',
        component: townCultural,
      }, {
        path: '/cultureDetail',
        name: 'cultureDetail',
        component: cultureDetail,
      }, {
        path: '/YuTeam',//豫剧团
        name: 'YuTeam',
        component: YuTeam,
      }, {
        path: '/YuTeam/yuDetails',//豫剧团
        name: 'yuDetails',
        component: yuDetails,
      }, {
        path: '/OtherTeam',//其他团队
        name: 'OtherTeam',
        component: OtherTeam,
      }, {
        path: '/ScenicSpot',//魅力景区
        name: 'ScenicSpot',
        component: ScenicSpot,
      }, {
        path: '/SpotDetails',//景区 详情页
        name: 'SpotDetails',
        component: SpotDetails,
      }, {
        path: '/visitPlan',//旅行攻略
        name: 'visitPlan',
        component: visitPlan,
      }, {
        path: '/visitPlanDetails',//旅行攻略详情页面
        name: 'visitPlanDetails',
        component: visitPlanDetails,
      }, {
        path: '/oldHouse',//袁世凯故居
        name: 'oldHouse',
        component: oldHouse,
      }, {
        path: '/Enforcement',//文化执法
        name: 'Enforcement',
        component: Enforcement,
      }, {
        path: '/train',//文化执法
        name: 'train',
        component: train,
      }, {
        path: '/ktv',//文化执法
        name: 'ktv',
        component: ktv,
      }, {
        path: '/DigitalSource',//数字资源
        name: 'DigitalSource',
        component: DigitalSource,
      }, {
        path: '/DigitalScreen',//数字大屏
        name: 'DigitalScreen',
        component: DigitalScreen,
      }, {//用户首页
        path: "/user",
        name: "user",
        component: user,
      }, {
        path: '/vr',
        name: 'vr',
        component: vr,
      }, {
        path: '/about',
        name: 'about',
        component: about,
      }, {
        path: '/tenseMore',
        name: 'tenseMore',
        component: tenseMore,
      }, {
        path: '/tenseMore/tenseDetails',
        name: 'tenseDetails',
        component: tenseDetails,
      }, {
        path: '/historyList',
        name: 'historyList',
        component: historyList,
      }, {
        path: '/otherTeamDetail',
        name: 'otherTeamDetail',
        component: otherTeamDetail,
      }
    ]
  }, {
    path: "/login",//登录页面
    name: 'login',
    component: login,
  }, {
    path: "/regiest",//登录页面
    name: 'regiest',
    component: regiest,
  },
  {
    path: '/mobile/VR',//首页，
    name: 'mobileVR',
    component: mobileVR,
  },
  {
    path: '/mobileVideo',//首页，
    name: 'mobileVideo',
    component: mobileVideo,
  },
  {//重定向，不知道去哪，就去首页
    path: '/*',
    redirect: '/home'
  },
]
// routes.beforeEach((to, from, next) => {
//   / firefoxdocument.documentElement.scrollTop = 0next()
// })
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(async (to, from, next) => {
  window.scrollTo(0, 0);//跳转页面后，回到顶部
})
export default router
