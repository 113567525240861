<template>
  <div id="app">
    <router-view />

    <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop> -->
  </div>
</template>
<script>
import { setToken } from "@/util/auth"
export default {
  data() {
    return {
      loginUserInfo: {},
      screenWidth: document.body.clientWidth, // 屏幕尺寸
      screenHeight:window.innerHeight,
    }
  },
  mounted() {
    console.log("screenWidth",this.screenWidth)
    console.log("screenHeight",this.screenHeight)
    document.querySelector("html").style.height = `${window.innerHeight}px`;//适配手机端防止底部留白
  },
  created() {
    let loginUserInfo = JSON.parse(localStorage.getItem("userInfo"))
    let token = localStorage.getItem('token')
    if (loginUserInfo) {
      this.$nextTick(() => {
        this.$store.commit('user/SET_USERINFO', loginUserInfo)
      })
      setToken(token)
    }
  },
  destroyed(){

  },
  beforeDestroy(){
  },
  methods: {

  }
}
</script>

<style href="@/theme/myStyle.less"></style>
<style lang="less">
* {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  padding: 0px;
  margin: 0px;
  // background-color: #000;
  width: 100%;
  // overflow-x: hidden !important;
  // min-width: 1350px;
  // overflow: scroll;
  .w-e-image-container{
    width: 100%;
  }
}

// 公共样式

</style>
