<template>
  <div class=" h-2rem text-left flex-row flex-ac">
    <div class="line"></div>
    <p class="ml-10">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: "mySection",
  components: {},
  props: {
    title: {
      default: "标题名称",
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
.line {
  height: 60%;
  width: 3px;
  background-color: @Primary;
}
</style>