<template>
  <div class="flex-1">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
    </div>
    <div class="flex-row flex-wrap  w-75rem m-auto mt-10">
      <div v-if="scenicList.length">
        <scenicSpotItem class="spotItem" @onClick="goPage('/SpotDetails', { id: item.id })"
          v-for="(item, index) in scenicList" :pic="item.pic" :name="item.name"
          :openTime="item.openTime + '~' + item.closeTime" :key="index" :intro="item.intro" />
      </div>
      <div class="bgc-w w-75rem mt-10" v-else>
        <el-empty description="暂无景区"
          image="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/miss.png"></el-empty>
      </div>
      <div class="mh-30 flex-row flex-jsb w-100">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { myRadio, myBreadCrumb, mySection, visitPlanItem, myCommonBox, scenicSpotItem, } from "@/components/index.js";
import commmonApi from "@/api/request/common";
import scenicApi from "@/api/request/scenic"
export default {
  name: "scenicSpot",
  components: {
    myRadio,
    myBreadCrumb,
    mySection,
    visitPlanItem,
    myCommonBox,
    scenicSpotItem,
  },

  data() {
    return {
      searchParams: {},
      menuList: [
        {
          path: "",
          name: "魅力景区",
        },
        {
          path: "",
          name: "旅行攻略",
        },
        {
          path: "/visitPlan",
          name: "景区介绍",
        },
      ],
      selection: [],
      scenicList: [],
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      total: 0,
      queryInfo: {
        pageNo: 0,
        pageSize: 8,
      },
    };
  },
  created() {
    this.getClass();
    this.getList();
  },
  methods: {
    changeType(e) {
      this.searchParams[e.type.value] = e.data
      this.getList()
    },
    getList() {
      scenicApi.getScenicPointList({
        ...this.queryInfo,
        ...this.searchParams
      }).then(res => {
        if (res.success) {
          this.scenicList = res.data.list;
          this.total = res.data.searchParams.total
        }
      })
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getClass() {
      commmonApi.adminKey({
        keyName: 'scenic_spot',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map(async (item2, index2) => {
                arr.push({
                  label: item2.keyDescribe,
                  value: item2.keyValue
                })
              })
              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    goPage(e, params) {
      if (params) {
        this.$router.push({ path: e, query: params });
      } else {
        this.$router.push({ path: e });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.spotItem {
  // width: 25%;
  float: left;
  margin-right: 5px;
  margin-top: 10px;
}
</style>