<template>
  <div class="footer flex-row flex-jsa flex-ac">
    <div class="flex-col">
      <!-- 操作行 -->
      <ul class="operation-ul flex-row">
        <li class="operation-li font-sn-wb mr-20 curp" v-for="(item, index) in operation" :key="index"
          @click="goPage('/about', true)">
          <font>{{ item }}</font>
        </li>
      </ul>
      <!-- 信息行 -->
      <ul class="info-ul flex-row mt-10">
        <li class="info-li flex-row mr-30" v-for="(item, index) in infoData" :key="index">
          <template>
            <span class=" font-ss color-gray2">{{ item.name + '：' + item.value }}</span>
          </template>
        </li>
      </ul>
      <!-- 联系方式、地址行 -->
      <ul class="info-ul flex-row mt-10">
        <li class="info-li flex-row mr-30" v-for="(item, index) in connection" :key="index">
          <template>
            <span class=" font-ss color-gray2">{{ item.name + '：' + item.value }}</span>
          </template>
        </li>
      </ul>
      <!-- 站点 -->
      <ul class="info-ul flex-row mt-10">
        <li class="info-li flex-row mr-30" v-for="(item, index) in side" :key="index" @click="goPage(item.href, false)">
          <template>
            <span class=" font-sms color-gray3">{{ item.name + '：' + item.value }}</span>
          </template>
        </li>
      </ul>
    </div>
    <!-- <div> -->
    <img class="code-bar"
      src="https://bpic.51yuansu.com/pic2/cover/00/27/19/57fc383074f64_610.jpg?x-oss-process=image/resize,w_260/sharpen,100" />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "myFooter",
  data() {
    return {
      // operation: ["关于我们", "联系我们", "帮助中心", "标准规范", "意见反馈"],
      operation: ["关于我们", "联系我们", "意见反馈"],
      infoData: [
        { name: "平台建设方", value: "项城市文化广电和旅游局" },
        { name: "运营单位", value: "项城市文化广电和旅游局" },
      ],
      connection: [
        { name: "联系电话", value: "0394-4321058" },
        { name: "地址", value: "河南省项城市人民路" },
      ],
      side: [
        { name: "ICP备案号", value: "浙ICP备2023018603号-2", href: "http://beian.miit.gov.cn/" },
        { name: "版权所有", value: "项城市文化广电和旅游局" },
        // { name: "技术支持", value: "杭州妙动科技有限公司" },
      ],
    };
  },
  mounted() { },
  methods: {
    /**
     * 
     * @param {*} e 路径
     * @param {*} isThisStation  是否是本站内
     */
    goPage(e, isThisStation) {
      if (e) {
        if (isThisStation) {
          this.$router.push(e)
        } else {
          window.location.href = e
        }
      } else {
        return
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.footer {
  width: 100%;
  background-color: #fff;
  height: 200px;
  border-top: 1px solid #efefef;
  background-color: #fff;

  .operation-ul,
  .info-ul {
    list-style: none;
  }

  .code-bar {
    height: 80px;
    width: 80px;
  }

  .curp {
    cursor: pointer;
  }
}
</style>