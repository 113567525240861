<template>
  <div class="flex-1">
    <div id="remote-video" class="videoBox">
      <!-- <el-image v-if="!isLive" style="width: 12rem; height: 12rem;cursor: pointer;"
        :src="require('@/assets/common/noLive.png')" fit="cover" @click="startToWatch"></el-image> -->
      <video
        id="player-container-id"
        width="1000px"
        height="800px"
        preload="auto"
        playsinline
        webkit-playsinline
      ></video>
    </div>

    <div class="videoInfo">
      <div class="title">{{ liveData.liveName }}</div>
      <div class="createTime">创建时间：{{ liveData.createTime }}</div>

      <div class="intro">详情介绍：{{ liveData.liveIntro }}</div>
    </div>
  </div>
</template>

<script>
import commmonApi from "@/api/request/common";
import TCPlayer from "tcplayer.js";
import "tcplayer.js/dist/tcplayer.min.css";

export default {
  name: "live",
  data() {
    return {
      localStream: undefined,
      zg: {},
      token: "",
      userInfo: {},
      isLive: false,
      appId: parseInt(process.env.VUE_APP_LIVE_APPID), //直播的APPid
      serve: process.env.VUE_APP_LIVE_SERVE, //直播的服务器地址
      roomId: process.env.VUE_APP_LIVE_ROOMID, //直播的ID地址
      streamID: process.env.VUE_APP_LIVE_STREAMID, //直播的streamId
      liveData: {
        liveName: "暂无直播",
        createTime: "",
        liveIntro: "暂无直播，请等待或刷新页面",
      },
    };
  },
  created() {
    let token = localStorage.getItem("token");
    let userInfo = localStorage.getItem("userInfo");
    this.$nextTick(() => {
      this.token = token;
      this.userInfo = JSON.parse(userInfo);
      this.initLive();
    });
  },
  beforeDestroy() {
    if (this.isLive) {
      this.stopPull();
    }
  },
  destroyed() {
    if (this.isLive) {
      this.stopPull();
    }
  },
  methods: {
    // 开始直播
    startToWatch() {
      this.getNewerLive();
    },
    // 获取最新的直播的数据
    getNewerLive() {
      commmonApi.getNewerLive({}).then((res) => {
        console.log(res);
        if (res.success) {
          if (res.data.isLive == 1) {
            this.isLive = true;
            // 如果是这个在直播，就去请求直播的数据

            this.liveData = res.data;
          } else {
            this.$message("暂无直播，请稍等哦！");
          }
        }
      });
    },
    async initLive() {
      var player = TCPlayer("player-container-id", {
        sources: [
          {
            src: "webrtc://play.xcswhj.cn/xcwl/xcwl",
          },
        ],
        licenseUrl:
          "https://license.vod2.myqcloud.com/license/v2/1303829981_1/v_cube.license",
      });
      // player.src('webrtc://play.xcswhj.cn/xcwl/xcwl'); // url 播放地址
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

h1,
h4 {
  text-align: center;
}

.videoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#remote-video {
  width: 70rem;
  margin: 0 auto;
  height: 30rem;
  margin-top: 1rem;
  background-color: #000;
}

.videoInfo {
  width: 66rem;
  margin: 0 auto;
  height: 8rem;
  background-color: #ffffff;
  text-align: left;

  padding: 1rem 2rem;

  .title {
    font-size: 20px;
  }

  .createTime {
    font-size: 12px;
    color: #999999;
    margin-top: 5px;
  }

  .intro {
    font-size: 14px;
    margin-top: 5px;
  }
}
</style>