import { get, post, postImg } from "@/util/http"
import ApiContainer from "../url"
const activityApi = {
  getActivityList: params => get(ApiContainer.getActivityList, params),  //获取活动列表
  getActivityInfoById: params => get(ApiContainer.getActivityInfoById, params),  //获取活动详情
  signActivity: params => post(ApiContainer.signActivity, params),  //报名活动
  queryActivityEnroll: params => get(ApiContainer.queryActivityEnroll, params),//查询活动报名的单条信息
  updateActivity: params => post(ApiContainer.updateActivity, params),//更新活动数据
  getActivityEnrollList: params => get(ApiContainer.getActivityEnrollList, params),//获取报名列表
  getTrendsList: params => get(ApiContainer.getTrendsList, params),
  updateTrends: params => post(ApiContainer.updateTrends, params),//更新文化动态
  getTrendsDetails: params => get(ApiContainer.getTrendsDetails, params),//获取文化动态详情
}
export default activityApi