
import { get, post, postImg } from "@/util/http"
import ApiContainer from "../url"
const teamApi = {
  teamList: params => get(ApiContainer.teamList, params),  //获取团队列表
  getMenuList: params => get(ApiContainer.getMenuList, params),  //获取团队列表
  getMenuById: params => get(ApiContainer.getMenuById, params),//通过ID查询豫剧信息
  getMobileVideoList: params => get(ApiContainer.getMobileVideoList, params),//获取手机视频列表
  getTeamInfoById:params=>get(ApiContainer.getTeamInfoById,params),//获取团队详情
}
export default teamApi