<template>
  <div class=" flex-1 flex-row bgc-sp">
    <div class="digital m-auto p-20 bdr-4 flex-row flex-jsa">
      <div class="graf flex-col flex-jsb mb-20 ">
        <myCircle getId="id1" title="文化动态数" v-if="circle.activity" :circleData="circle.activity" />
        <myCircle getId="id2" title="文化服务中心数" v-if="circle.venue" :circleData="circle.venue" />
        <myCircle getId="id3" title="魅力景区数" v-if="circle.station" :circleData="circle.station" />
        <myCircle getId="id4" title="艺术团队数" v-if="circle.team" :circleData="circle.team" />
      </div>
      <div class="h-100">
        <bar echartsName="点赞记录" :likeNum="likeNum" v-if="likeNum" />
        <category v-if="cutData.length" title="预约记录" :cutData="cutData" />
      </div>
      <div class="h-100">
        <pie />
        <list />
        <!-- <bar /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { category, myCircle, bar, pie, list } from "@/components/index";
import commmonApi from "../../api/request/common";
export default {
  name: "digitalScreen",
  components: { category, myCircle, bar, pie, list },

  data() {
    return {
      msg: "fafa",
      circle: {},
      cutData: [],
      likeNum: undefined,
    };
  },
  mounted() {
    this.getCircleData();
    this.readLike();
    this.readCutNum();
  },
  methods: {
    // 折线图的数据
    readCutNum() {
      commmonApi.readCutNum({

      }).then(res => {
        if (res.success) {
          this.cutData = res.data;
        }
      })
    },
    // 条形图数据
    readLike() {
      commmonApi.readLike({
      }).then(res => {
        if (res.success) {
          this.likeNum = res.data
        }
      })
    },
    // 圆圈的数据
    getCircleData() {
      commmonApi.readCircle({
      }).then(res => {
        console.log(res);
        if (res.success) {
          this.circle = res.data;
          this.circle.venue = [
            { state: '村（社区）文化服务中心', num: 118 },
            { state: '镇（街道）文化服务中心', num: 76 },
          ];
          this.circle.station = [
            { state: '袁世凯故居', num: 12 },
            { state: '南顿故城', num: 4 },
            { state: '水寨印象', num: 4 },
            { state: '项城市博物馆', num: 8 },
            { state: '文化馆', num: 11 },
            { state: '植物园', num: 7 },
            { state: '张伯驹公园', num: 4 },
            { state: '驸马沟生态公园', num: 8 },
          ]
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../theme/myStyle.less");

.digital {
  width: 80rem;
}

.graf {
  height: 44rem;
}
</style>