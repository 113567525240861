<template>
  <div class="otherTeam bgc-w  b-shadow-m text-left ovf-h">
    <el-image style="width: 100%; height: 8rem" :src="img" fit="cover"></el-image>
    <div class="pv-20">
      <div class="font-18">{{ name }}</div>
      <!-- <p class="font-14 mt-10">时间:{{ time }}</p> -->
      <p class="font-14">地点:{{ place }}</p>
      <div class="flex-row font-14">
        <p>队长: {{ leader }} </p>
        <p class="ml-20">联系方式:{{ leaderTel }}</p>
      </div>
      <div class="font-14 flex-row">
        <p class="">介绍：</p>
        <p class="w-80 t-hd-two">{{ detais }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "豫剧团"
    },
    detais: {
      type: String,
      default: "详情",
    },
    leader: {
      type: String,
      default: "队长"
    },
    leaderTel: {
      type: String,
      default: '联系方式'
    },
    place: {
      type: String,
      default: ""
    },
    time: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.otherTeam {
  width: 100%;
  height: 15rem;

}
</style>