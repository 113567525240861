<template>
  <div class="w-90 h-8rem bgc-w flex-row flex-ac p-10 " @click="callBack">
    <el-image class="h-8rem w-20rem" :src="visitPic" fit="cover">
    </el-image>
    <div class="h-100 flex-col flex-as flex-jcs pl-10 w-100">
      <p class="font-1rem color-sp">{{ name }}</p>
      <div class="flex-row w-90 flex flex-ac font-12 color-gray3 mt-10  flex-as  ">
        <p class="">介绍：</p>
        <p class="w-90 text-left t-hd-two">{{ intro }}</p>
      </div>
      <!-- <p class="font-12 color-gray3 ">来源：A作者 </p> -->
      <p class="font-12 color-gray3 ">时间：{{ createTime }}</p>
      <p class="font-12 color-gray3">阅读数：{{ readNum }} 次</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "visitPlanItem",
  props: {
    name: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: "",
    },
    createTime: {
      type: String,
      default: '',
    },
    readNum: {
      type: Number,
      default: 0
    },
    visitPic: {
      type: String,
      default: '',
    }
  },
  data() {
    return {};
  },
  methods: {
    callBack() {
      this.$emit("onClick")
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>