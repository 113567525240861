<template>
  <div class="w-75rem m-auto mt-50 mb-50 bgc-w flex-1 ph-10">
    <tabBar class=" m-auto mt-20 w-90" title="场馆展示" :type="1" py="CHANG GUAN ZHAN SHI " @onClick="goPage('/venueList')" />
    <div v-if="venueList">
      <div class="w-90 m-auto flex-row flex-wrap pb-30 venueLine" v-for="(item, index) of venueList" :key="index">
        <div class=" icon_list">
          <img :src="item && item.memo || ''" class="svg_icon" />
          <p>______</p>
          <p>{{ item && item.keyDescribe }}</p>
        </div>
        <template v-if="item && item.items">
          <venueItem class="mt-20" v-for="item2 of item.items" :key="item2.id" :venueName="item2.name"
            :location="item2.location" :contactMan="item2.contactMan" :contactTel="item2.contactTel"
            :openTime="item2.openTime" :headPic="item2.headPic" @onClick="goPage(`/venueDetails?id=${item2.id}`)"
            :vrId="item2.vrId" @onCheckVr="goPage(`/vr?id=${item2.vrId}`)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { tabBar, myBreadCrumb, venueItem } from "@/components/index.js";
import venueApi from "@/api/request/venue.js";
import commmonApi from "@/api/request/common.js";
export default {
  name: "venue",
  components: { myBreadCrumb, tabBar, venueItem },
  data() {
    return {
      venueList: [],//场馆列表
      base_url: process.env.VUE_APP_IMG_BASE_URL,
    };
  },
  created() { },
  mounted() {
    this.getAdminKey();
    // this.getList();
  },
  methods: {
    getAdminKey() {
      commmonApi.adminKey({
        keyName: "venue",
        keyType: "belong"
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            venueApi.getVenueList({
              isdelete: '0',
              pageSize: 3, pageNo: 0,
              belong: item.keyDescribe,//所属类型(0-文化馆，1-博物馆，2-图书馆，3-南顿古城，4-袁世凯故居，5，人民会堂)
            }).then(res2 => {
              if (res2.success) {
                this.$set(this.venueList, index, {
                  ...item,
                  items: res2.data.list
                })
              }
            })
          })
        }
      })
    },
    getList(belong = '0', index) {

    },
    goPage(e,params) {
      this.$router.push(e);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.venueLine {
  min-height: 333px;
}

.icon_list {
  margin-top: 1.3rem;
  margin-right: 1rem;
  border-radius: 2rem;
  border: 3px solid @Primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
  }
}

.svg_icon {
  height: 120px;
}
</style>