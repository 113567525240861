<template>
  <div class="flex-col h-100 w-100">
    <div class="flex-1 ">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="收藏时间" prop="createTime">
        </el-table-column>
        <el-table-column label="收藏内容" prop="partName">
        </el-table-column>
        <template slot="empty">
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
    </div>

    <el-pagination background layout="prev, pager, next" :total="total" class="mt-10 " @size-change="changeSize"
      @current-change="changePage" />
  </div>
</template>

<style>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

<script>
import commmonApi from '../../api/request/common';
export default {
  data() {
    return {
      queryInfo: {
        pageSize: 8,
        pageNo: 0,
      },
      total: 0,
      tableData: [
      ],
    };
  },
  mounted() {
    let token = this.$store.state.user.token;
    console.log(token)
    if (token) {
    }
  },
  created() {

  },
  methods: {
    changeSize(e) {

    },
    changePage(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
    getList() {
      commmonApi.queryLikeDigtal({
        ...this.queryInfo
      }).then(res => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.searchParams.total
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>