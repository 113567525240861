<template>
  <div class="otherTeam bgc-w bdr-4 b-shadow-m text-left ovf-h curp" @click="callBack">
    <el-image style="width: 100%; height: 11rem" :src="img" fit="cover"></el-image>
    <div class="pv-20">
      <div class="font-18">{{ name }}</div>
      <p class="font-14 mt-10">时间:{{ time }}</p>
      <p class="font-14">地点:{{ place }}</p>
      <div class="flex-row font-14">
        <p>队长: {{ leader }} </p>
        <p class="ml-20">联系方式:{{ leaderTel }}</p>
      </div>
      <div class="font-14 flex-row">
        <p class="">介绍：</p>
        <p class="w-80 t-hd-two">{{ detais }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    detais: {
      type: String,
      default: "",
    },
    leader: {
      type: String,
      default: ""
    },
    leaderTel: {
      type: String,
      default: ''
    },
    place: {
      type: String,
      default: ""
    },
    time: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
    }
  },
  data() {
    return {

    }
  },
  methods:{
    callBack(){
      this.$emit("onClick")
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.otherTeam {
  width: 18rem;
  height: 20rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
</style>