<template>
  <div class="bgc-p bdr-4 p-10">
    <h1 class="color-w pb-10">活动场馆</h1>
    <div id="pie" :style="{ width: '20rem', height: '30rem', padding: '0 1rem', color: 'ffffff' }"></div>
  </div>
</template>

<script>
export default {
  name: "myBar",
  data() {
    return {
      msg: "fafa",
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("pie"));
      // 绘制图表
      myChart.setOption({
        // title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'left',
          textStyle:{
            color:"#ffffff"
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '60%',
            top:"35%",
            label: {

              fontSize: 18,
              color: '#fff'
            },
            data: [
              { value: 8, name: '东方街道胡营文化广场' },
              { value: 12, name: '中心文化广场' },
              { value: 9, name: '亿洲新世界文化广场' },
              { value: 12, name: '公园文化广场' },
              { value: 11, name: '市钧园文化广场' },
              { value: 8, name: '张伯驹纪念馆文化广场' },
              { value: 6, name: '水寨街道公园里文化广场' },
              { value: 8, name: '植物园文化广场' },
              { value: 10, name: '水寨街道新大桥文化广场' },
              { value: 8, name: '火车站广场' },
              { value: 5, name: '水寨街道名仕文化广场' },
              { value: 10, name: '袁氏古民居文化广场' }, 
              { value: 10, name: '花园街道后高营文化广场' },
              { value: 5, name: '驸马沟廉政文化广场' },
              { value: 23, name: '南顿故城文化广场' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(255,255,255,1)'
              }
            }
          }
        ]
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>