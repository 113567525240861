import { render, staticRenderFns } from "./mySection.vue?vue&type=template&id=74bf60fd&scoped=true&"
import script from "./mySection.vue?vue&type=script&lang=js&"
export * from "./mySection.vue?vue&type=script&lang=js&"
import style0 from "./mySection.vue?vue&type=style&index=0&id=74bf60fd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bf60fd",
  null
  
)

export default component.exports