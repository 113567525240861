import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css';
// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 百度地图
import BaiduMap from 'vue-baidu-map';
// 可视化图表
import * as echarts from "echarts";
// 动画组件
import 'animate.css';

Vue.prototype.$echarts = echarts;
Vue.use(BaiduMap, { ak: process.env.VUE_APP_BAIDU_MAP_AK })
Vue.use(VueAwesomeSwiper, /* {  default global options } */)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
