import { get, post, postImg } from "@/util/http"
import ApiContainer from "../url"
const commmonApi = {
  getActivityList: params => get(ApiContainer.getActivityList, params),  //获取活动列表
  adminKey: params => get(ApiContainer.adminKey, params),//关键字查询
  likeRecord: params => get(ApiContainer.likeRecord, params),//点赞
  likeDigtal: params => get(ApiContainer.likeDigtal, params),//点赞数据
  queryLikeDigtal: params => get(ApiContainer.queryLikeDigtal, params),//点赞数据
  getVRlist: params => get(ApiContainer.getVRlist, params),//获取VR列表
  getLiveToken: params => get(ApiContainer.getLiveToken, params),//获取直播Token
  getNewerLive: params => get(ApiContainer.getNewerLive, params),//最新的直播数据
  email: params => post(ApiContainer.email, params),//发送邮箱验证码
  sms: params => post(ApiContainer.sms, params),//发送短信验证码
  checkHasUser:params=>get(ApiContainer.checkHasUser,params),//注册前先了解改手机号是否允许注册
  loginByText: params => post(ApiContainer.loginByText, params),//验证码登录
  readCircle: params => get(ApiContainer.readCircle, params),//圆圈数据
  readLike: params => get(ApiContainer.readLike, params),//获取总数
  readCutNum: params => get(ApiContainer.readCutNum, params),//获取折线统计图的数据
  getLiveList: params => get(ApiContainer.getLiveList, params),//获取直播
  feedBackList: params => get(ApiContainer.feedBackList, params),//意见反馈列表
  updateFeedBack: params => post(ApiContainer.updateFeedBack, params),//更新意见反馈
  addFeedBack: params => post(ApiContainer.addFeedBack, params),//新增意见反馈
  register: params => post(ApiContainer.register, params),//用户注册
  login: params => post(ApiContainer.login, params),//用户登录
  updateUserInfo: params => post(ApiContainer.updateUserInfo, params),//更改用户信息
  getVrRoomList: params => get(ApiContainer.getVrRoomList, params),//获取vr列表
}
export default commmonApi