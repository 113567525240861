<template>
  <div class="flex flex-1 flex-row mt-30 flex-jc w-80 m-auto" v-if="detailsData">
    <div class="left">
      <div class="flag">
        <p class=" flag-title ph-10">最新消息</p>
      </div>
      <ul class="list">
        <li class="listItem" v-for="(item, index) in activityList" :key="index" @click="goPage(item)">
          <p class="circle"></p>
          <p class="name">{{ item.title }}</p>
        </li>
      </ul>
    </div>
    <div class="right">
      <div class=" title bgc-w ph-10 ">
        <h2 class="color-gray">{{ detailsData.title }}</h2>
        <p class="font-16 color-gray2  text-center m-10"> {{ detailsData.intro }}</p>
        <div class="flex-row flex-jsa w-70 m-auto">
          <p class="font-14 color-gray3">创建时间：{{ detailsData.createTime }}</p>
          <p class="font-14 color-gray3">发布单位：{{ detailsData.createBy }}</p>
          <p class="font-14 color-gray3">阅读次数：{{ detailsData.readNum }}</p>
          <!-- <p>阅读次数{{ readNum }}</p>
        <p>点赞次数{{ likeNum }}</p> -->
        </div>
      </div>
      <div class="container bgc-w p-20 mh-50h">
        <div class="h-100 w-100 ph-10 " v-html="detailsData.content"></div>
      </div>
      <div class="otherFile flex-col bgc-w">
        <div class="curp fileItem" v-for="(item, index) in detailsData.fileList" :key="index" @click="downLoadFile(item)">
          {{ item.fileName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import activityApi from '@/api/request/activity.js';
export default {
  data() {
    return {
      detailsData: {},
      activityList: [],
    }
  },
  created() { },
  mounted() {
    let query = this.$route.query;
    if (query.id) {
      this.getDetiail(query);
      this.getTrends();
    }
  },
  methods: {
    // 下载文件
    downLoadFile(item) {
      let today = new Date();
      let setDay = new Date(this.detailsData.fileOutTime)
      // fileOutTime 如果不设置，就是长期有效
      if (!this.detailsData.fileOutTime) {
        window.location.href = item.fileUrl
      } else {
        console.log(item)
        console.log(today)
        console.log(setDay)
        if (today < setDay || today == setDay) {
          window.location.href = item.fileUrl
        } else {
          this.$message("已超出公示时间")
        }
  }
},
// 跳转页面
goPage(e) {
  this.getDetiail(e);
},
// 获取左侧列表
getTrends() {
  activityApi.getTrendsList({
    pageSize: 6,
    pageNo: 0
  }).then(res => {
    if (res.success) {
      let arr = res.data.list
      this.activityList = res.data.list;
    }
  })
},
// 获取详情
getDetiail(e) {
  let loading = this.$loading({
    lock: true,
    text: '正在加载',
    spinner: 'el-icon-loading',
    background: 'rgba(255,255,255, 0.7)'
  });
  activityApi.getTrendsDetails({ id: e.id }).then(res => {
    loading.close();
    if (res.success) {
      this.detailsData = res.data[0]
    } else {
      this.$message({
        message: '该动态不存在',
        onClose: () => {
          this.$router.go(-1);
        }
      });
    }
  })
},
  },
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.left {
  width: 20%;
  padding: 10px 20px;

  .flag {
    background-color: #fff;

    .flag-title {
      color: #ffffff;
      background: #452a0e;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  .list {
    list-style: circle;
    min-height: 150px;
    background-color: #ffffff;

    .listItem {
      background-color: #ffffff;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      flex-direction: row;
      padding: 15px 0px;
      color: #452a0e;
      font-size: 14px;
      align-items: center;
      cursor: pointer;
      text-align: left;

    }

    .circle {
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background-color: #ffca64;
      margin: 0 10px;
    }

    .name {
      width: 90%;
      color: #936739;
    }

    .listItem:hover {
      padding-right: 10px;
      transition: all .3s;
      margin-left: -10px;
      color: #faa64f;
    }

    .listItem:hover .circle {
      background-color: #faa64f;
    }
  }
}

.right {
  width: 75%;
  overflow: hidden;
  margin: 10px 10px;
  border-radius: 4px;

  .title {
    border-bottom: 1px solid #ebebeb;
  }
}

.otherFile {
  border-top: 1px solid #e9e9e9;
  text-align: left;
  padding: 10px 40px;

  .fileItem {
    margin: 10px 0;
    color: #949494;
    letter-spacing: 0px;
    font-size: 14px;
    transition: all .5s;
  }

  .fileItem:hover {
    color: #0023be;

  }
}
</style>