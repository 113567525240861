<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <template v-for="(item, index) in menuList">
      <!-- 需要跳转 -->
      <el-breadcrumb-item v-if="item.path" :to="{ path: item.path ? item.path : '' }"
        :key="item.path + index">{{ item.name }}</el-breadcrumb-item>
      <!-- 不需要跳转 -->
      <el-breadcrumb-item v-else :key="item.name + index">{{ item.name }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "myBreadCrumb",
  components: {},
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    this.menuList = this.list
      ? this.list
      : [
        {
          path: "",
          name: "目录名称1",
        },
        {
          path: "",
          name: "目录名称2",
        },
      ];
  },
};
</script>

<style></style>