<template>
  <div class=" w-20rem h-10rem bdw-1 bdr-4 bdc-p bgc-p flex-row flex-ac">
    <div :id="getId" :style="{ width: '9rem', height: '9rem' }"></div>
    <div class="color-w flex-col mb-40 ">
      <h2 class="">{{ title }}</h2>
      <h2 class="mt-10">{{ sum }}</h2>
    </div>
  </div>
</template>

<script>
import { CONSTANTS } from 'photo-sphere-viewer';
export default {
  name: "myCircle",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    getId: {
      type: String,
      default: "id",
    },
    circleData: {}
  },
  data() {
    return {
      msg: "fafa",
      circleValue: [],
      myChart: {},
      sum: 0,
    };
  },
  created() {
    this.$nextTick(async () => {
      if (this.getId) {
        let circleValue = [];
        await this.circleData.map((item, index) => {
          let keys = Object.keys(item)
          circleValue[index] = {
            value: item[keys[1]], name: item[keys[0]],
          }
          this.sum = this.sum + item[keys[1]]
        })
        this.circleValue = circleValue;
        this.drawLine()
      }
    });
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById(this.getId));
      // 绘制图表
      this.myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "5%",
          containLabel: true,
        },
        series: [
          {
            name: this.title,
            type: "pie",
            radius: ["75%", "90%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 3,
              borderColor: "#F0994A",
              borderWidth: 1,
            },
            label: {
              show: false,
              position: "center",
            },

            emphasis: {
              label: {
                show: true,
                fontSize: 12,
                fontWeight: "bold",
              },
            },
            data: this.circleValue
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>