<template>
  <div class=" flex-col flex-js bgc-w w-100 pt-20  pb-30 bdr-4">
    <div v-for="(item, index) in radio" :key="index" class="mt-10 flex-row flex-ac ml-30 font-wber">
      <span class="mr-10 title">{{ item&&item.type||'' }}：</span>
      <el-radio-group v-model="radios[index]" @change="change(item, index)">
        <template v-if="item&&item.data">
        <el-radio-button @click.native="clickRadio($event, item2, index2, item, index)" class="bdc-sp"
          :label="item2.value || item2" v-for="(item2, index2) in item.data" :key="item2.value">{{
            item2.label || item2 }}</el-radio-button>
            </template>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "myRadio",
  props: {
    selection: {
    },
  },
  data() {
    return {
      radios: [],
      radio: [],
      isSelected: [],
    };
  },
  mounted() {
    if (this.selection) {
      this.radio = this.selection ? this.selection : [];
    }
  },
  methods: {
    clickRadio(e, item2, index2, item, index) {
      if (e.target.tagName === "INPUT") {
        if (this.radios[index] == item2.value) {
          this.$set(this.radios, index, '');
          this.change(item, index);
        } else {
        }
      }
    },
    change(e, i) {
      this.isSelected = this.radios[i];
      this.$emit('choice', { type: e, data: this.radios[i], radio: this.radio, radios: this.radios });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../theme/myStyle.less");

.title {
  min-width: 5em;
}

.el-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.el-radio-button {
  border-left: 1px solid #dcdfe6;
}

.el-radio-button:first-child .el-radio-button__inner {
  box-shadow: none !important;
  border-left: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.el-radio-button{
  border: none !important; 
}
</style>