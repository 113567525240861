<template>
  <div>
    <div :class="'typedText'" v-for="(item, index) in typeTexts" :key="index">{{ item }}</div>
  </div>
</template>

<script>
export default {
  name: "typed",
  props: {
    typedList: [],
    loop: {
      type: Boolean,
      default: true,
    },
    speed: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      typedText: "",
      typeTexts: [],
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      let j = 0;
      let k = 0;
      this.typedText = "";
      if (this.typedList.length <= 0) {
        console.error("typedList数据缺失");
        return;
      }
      let arr = this.typedList[k].split("");
      let timer = setInterval(() => {
        if (j < arr.length) {
          this.$set(this.typeTexts, k, (this.typeTexts[k] || '') + arr[j++]);
        } else {
          if (k < this.typedList.length - 1) {
            k++;
            j = 0;
            arr = this.typedList[k].split("");
          } else {
            clearInterval(timer);
            if (this.loop) {
              this.start();
            }
          }
        }
      }, this.speed);
    },
  },
};
</script>
<style lang="less" scoped>
.typedText{
  text-align: left;
}
.typedText:first-child {
  font-size: 60px;
}

.typedText:nth-child(2) {
  font-size: 40px;
}

.typedText:last-child::after {
  content: "|";
  animation: blink 1s infinite;
}

.typedText::after {
  content: " ";
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
