import { render, staticRenderFns } from "./scenicSpotItem.vue?vue&type=template&id=50cdfb36&scoped=true&"
import script from "./scenicSpotItem.vue?vue&type=script&lang=js&"
export * from "./scenicSpotItem.vue?vue&type=script&lang=js&"
import style0 from "./scenicSpotItem.vue?vue&type=style&index=0&id=50cdfb36&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50cdfb36",
  null
  
)

export default component.exports