<template>
  <div class="flex-1">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <div class=" mt-20">
        <myRadio :selection="selection" @choice="changeType" />
      </div>
      <div class=" flex-row flex-jsb mt-30 pb-30 w-100 ">
        <div class="flex-col flex-wrap  w-80  " v-if="list.length > 0">
          <visitPlanItem class="mt-10 curp" v-for="(item, index) in  list " :key="index" :name="item.visitName"
            :intro="item.intro" :createTime="item.createTime" :readNum="item.readNum" :visitPic="item.visitPic"
            @onClick="goPlan(item)" />
        </div>
        <div class="bgc-w w-70 " v-else>
          <el-empty description="暂无景区"
            image="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/miss.png"></el-empty>
        </div>
        <div class="mh-10rem bgc-w w-25 bdr-4 pb-20">
          <mySection title="推荐攻略" />
          <div v-if="pushList.length">
            <div v-for="(item, index) in pushList" :key="index">
              <myCommonBox :name="item.visitName" :url="item.visitPic" @onClick = "goPlan(item)" />
            </div>
          </div>
          <el-empty v-else description="暂无热门景点"></el-empty>
        </div>
      </div>
      <div class="mh-30 flex-row flex-jsb">
        <div>共计 {{ total }} 条</div>
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="queryInfo.pageSize" :current-page.sync="queryInfo.pageNo" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  myRadio,
  myBreadCrumb,
  mySection,
  visitPlanItem,
  myCommonBox,
} from "@/components/index.js";
import visitApi from "@/api/request/visit";
import commmonApi from "@/api/request/common";
export default {
  name: "visitPlan",
  components: { myRadio, myBreadCrumb, mySection, visitPlanItem, myCommonBox },
  data() {
    return {
      menuList: [
        {
          path: "",
          name: "魅力景区",
        },

        {
          path: "/visitPlan",
          name: "旅行攻略",
        },
      ],
      list: [],
      queryInfo: {
        pageNo: 0,
        pageSize: 8,
      },
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      total: 0,
      selection: [],
      searchParams: {},
      pushList: [],//推介列表
    };
  },
  mounted() { },
  created() {
    this.getList();
    this.getClass();
    this.getPush();
  },
  methods: {
    goPlan(e) {
      this.$router.push({
        path: "/visitPlanDetails",
        query: {
          id: e.id
        }
      })
    },
    getClass() {
      commmonApi.adminKey({
        keyName: 'visit_plan',
        keyType: 'search_type',
      }).then(res => {
        if (res.success) {
          res.data.list.map(async (item, index) => {
            commmonApi.adminKey({
              keyType: item.keyValue
            }).then(res2 => {
              let arr = [];
              res2.data.list.map(async (item2, index2) => {
                arr.push({
                  label: item2.keyDescribe,
                  value: item2.keyValue
                })
              })

              this.$set(this.selection, index, {
                type: item.keyDescribe,
                value: item.keyValue,
                data: arr
              })
            })
          })
        }
      })
    },
    changeType(e) {
      this.searchParams[e.type.value] = e.data
      this.getList(this.searchParams)
    },
    // 获取推介列表
    getPush() {
      visitApi.getScenicVisitList({
        push: 1,
        pageNo: 1,
        pageSize: 5,
      }).then(res => {
        this.pushList = res.data.list
      })
    },
    // 获取列表
    getList() {
      visitApi.getScenicVisitList({
        ...this.queryInfo,
        ...this.searchParams,
        isDelete: 0,
      }).then(res => {
        if (res.success) {
          this.list = res.data.list;
          this.total = res.data.searchParams.total
        }
      })
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();
    },
  }
};
</script>
  
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>