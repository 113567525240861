<template>
  <div class="digitalSource bgc-w bdr-4 ovf-h relative" @click="callBack">
    <el-image style="width: 100%; height: 100%" :src="url" fit="cover"></el-image>
    <div>
      <!-- <el-image
        style="position:absolute;left:50%;top:50%;margin-left: -50px;margin-top: -50px;  width: 100px; height: 100px"
        :src="icon[type]" fit="cover">  </el-image> -->
      <div class="absolute h-4rem w-100 bgc-sp b-0 l-0">
        <div class="bgc-sp absolute aboImg ">
          <el-image style="width:2rem; height: 2rem" :src="icon[0]" fit="cover">
          </el-image>
        </div>
        <div class="color-w text-left word">
          <h3 class="digitalDetails">{{ name }}.{{ type }}</h3>
          <p class="font-12">{{ intro }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import file from "@/assets/common/file.png"
import img from "@/assets/common/img.png"
import mov from "@/assets/common/mov.png"
import music from "@/assets/common/music.png"
export default {
  name: 'digitalSourceItem',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    url: {
      default: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      type: String,
    }
  },
  data() {
    return {
      file: file,
      img: img,
      mov: mov,
      music: music,
      icon: [file, img, mov, music],
    }
  },
  mounted() { },
  created() { },
  methods: {
    callBack() {
      this.$emit('onClick');
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.digitalSource {
  width: 18rem;
  margin-right: 0.32rem;
  margin-left: 0.32rem;
  height: 15rem;
  margin-top: 0.5rem;
  cursor: pointer;
  .aboImg {
    border-radius: 2rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: -30%;
    margin-left: 1.5rem;
    justify-content: center;
  }

  .word {
    margin-left: 5.5rem;
  }
}
.digitalDetails{
  white-space:nowrap;

overflow:hidden;

text-overflow:ellipsis;
}
</style>