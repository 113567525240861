<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <!-- 顶部标题 -->
      <div class="w-100 ml-5 h-20rem bgc-w mt-20 flex-row flex-ac bdr-4 b-shadow">
        <el-image v-if="activity && activity.activiytPosters" class="h-90 w-30rem ml-20 bdr-4"
          :src="activity.activiytPosters" fit="cover"></el-image>
        <div v-else class="noneImg">正在加载</div>
        <div class="h-90 ml-20  flex-col font-wl flex-1 text-left">
          <div class="color-p font-1_5rem ">{{ activity.activityName }}</div>
          <div class="color-gray2 font-0_8rem mt-10">日期：{{ activity.startTime && activity.startTime.split(" ")[0] }}-{{
            activity.endTime && activity.endTime.split(" ")[0] }}</div>
          <div class="color-gray2 font-0_8rem mt-10">举办方：{{ activity.holder }}</div>
          <div class="color-gray2 font-0_8rem mt-10">创建时间：{{ activity.createTime }}</div>
          <div class="color-gray2 font-0_8rem mt-10">地址：{{ activity.activityLocation }}</div>
          <div class="color-gray2 font-0_8rem mt-10 pr-30 t-hd-four flex-1">简介：<span>{{ activity.intro }}</span></div>
          <div class="flex-row flex-je mr-30">
            <div class="mt-20  flex flex-jc flex-ac" v-if="activity.activityType == 1">
              <el-button v-if="time < activity.startTime" :disabled="true" round>报名未开始</el-button>
              <el-button v-else-if="time > activity.endTime" :disabled="true" round>活动已结束</el-button>
              <el-button v-else  :type="isEnrolled ? 'success' : 'primary'" :plain="isEnrolled"
                :disabled="isEnrolled" round @click="signActivity">{{ isEnrolled ? ' 报名成功 ' : '我要报名' }}</el-button>
           
              <span class="ml-10 font-1_3rem color-gray">已报名 {{ total }}人</span>
            </div>
            <div class="mt-20 ml-20 flex flex-jc flex-ac">
              <el-button icon="el-icon-view" circle type="primary" plain> </el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ activity.watchTimes }}</span>
            </div>
            <div class="mt-20 ml-20 flex flex-jc flex-ac">
              <el-button icon="el-icon-thumb" circle type="primary" plain @click="like"> </el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ activity.likeTimes }}</span>
            </div>
            <div class="mt-20 ml-20">
              <el-button icon="el-icon-share" circle type="primary" plain @click="sharePage(activity)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 下面 -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50 ">
        <div class="w-65  ph-10 bgc-w mh-50h  ">
          <mySection title="活动介绍" />
          <div class="content">
            <div v-if="activity.activityContext" v-html="activity.activityContext"></div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
        </div>
        <div class="w-30 bgc-w ph-10 mh-50h">
          <mySection title="热门活动" />
          <div class="">
            <div v-if="pushList.length">
              <div v-for="(item, index) in  pushList " :key="index">
                <div class="pushItem" @click="goPushItem(item)">
                  <div class="tags">
                    <el-tag type="warning" effect="plain"> {{ item.activityClass }} </el-tag>
                  </div>
                  <el-image style="width: 100%; height: 10em" :src="item.activiytPosters" fit="cover"></el-image>
                  <div class="pushItemName">
                    <div>{{ item.activityName }}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import activityApi from '@/api/request/activity.js';
import { getCurrentTime } from "@/util/time"
import {
  myRadio,
  myBreadCrumb,
  activityItem,
  mySection,
} from "@/components/index.js";
import share from "@/util/share";
import commmonApi from '../../api/request/common';
export default {
  name: "activityDetails",
  components: { myRadio, myBreadCrumb, activityItem, mySection },
  data() {
    return {
      time: '',
      activityId: '',
      activity: {},
      likeTimes: 0,
      isEnrolled: false,
      isInTime: true,
      total: 0,//总报名人数
      pushList: [],//推荐内容
      token: '',//用来判断用户是否登录
      // 顶部的目录
      menuList: [
        {
          path: "/Activity",
          name: "文化活动",
        },
        {
          path: "/ActivityList",
          name: "活动列表",
        },
      ],
    };
  },
  mounted() {
    this.time = getCurrentTime('YYYY-MM-DD');
    let query = this.$route.query;
    if (query && query.id) {
      this.token = this.$store.state.user.token
      this.getActivityInfoById(query.id);
      this.queryActivityEnroll(query.id);//获取该用户是否已经报名
      this.getActivityEnrollList(query.id)//获取报名信息
      this.getpushData();//获取推荐的列表
      this.activityId = query.id
      // this.getLikeData();
    }
  },
  methods: {
    // 去推荐的页面
    goPushItem(e) {
      this.$router.push({
        path: '/activityDetails',
        query: { id: e.id }
      })
      let loading = this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255, 0.7)'
      });
      setTimeout(() => {
        this.token = this.$store.state.user.token
        this.getActivityInfoById(e.id);
        this.queryActivityEnroll(e.id);//获取该用户是否已经报名
        this.getActivityEnrollList(e.id)//获取报名信息
        this.getpushData();//获取推荐的列表
        this.activityId = e.id
        loading.close();
      }, 500);
    },
    // 获取推荐的内容
    getpushData() {
      activityApi.getActivityList({
        push: 1,
        pageSize: 3,
        pageNo: 1
      }).then(res => {
        if (res.success) {
          this.pushList = res.data.list
        }
      })
    },
    // 查阅这个用户是否已经报名
    queryActivityEnroll(id) {
      if (this.token) {
        activityApi.queryActivityEnroll({
          activityId: id,
        }).then(res => {
          if (res.data.length) {
            this.isEnrolled = true;
          }
        })
      }
    },
    // 查看这个活动多少人报名
    getActivityEnrollList(id) {
      activityApi.getActivityEnrollList({
        activityId: id,
      }).then(res => {
        if (res.success) {
          this.total = res.data.searchParams.total
        }
      })
    },
    //用户报名活动
    signActivity() {
      if (!this.isEnrolled) {
        activityApi.signActivity({
          activityId: this.activity.id,
          activityName: this.activity.activityName,
          activityType: this.activity.activityType,
          activePlace: this.activity.holder
        }).then(res => {
          if (res.success) {
            this.isEnrolled = true;
            this.$message.success(res.msg)
            this.total = this.total + 1;
          } else {
            this.$message(res.msg)
          }
        })
      } else {
        this.$message('您已报名，无需报名')
      }
    },
    // 用户点击喜欢
    like() {
      commmonApi.likeRecord({
        partName: '文化活动',
        partId: this.activityId,
        isLike: '1',
      }).then(res => {
        if (res.success) {
          activityApi.updateActivity({
            likeTimes: this.activity.likeTimes + 1,
            id: this.activity.id
          }).then(res2 => {
            if (res2.success) {
              this.$message.success("点赞成功")
              this.activity.likeTimes = this.activity.likeTimes + 1;
            }
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    sharePage(e) {
      share.share2QQ(
        {
          url: 'http://yuyitang.xyz:3333',
          desc: e.activityContext,
          title: e.activityName,
        }
      )
    },
    getActivityInfoById(id) {
      activityApi.getActivityInfoById({
        id: id
      }).then(res => {
        if (res.success) {
          this.activity = res.data;
          // let today = new Date().getTime();
          // let endDay = new Date(this.activity.endTime).getTime();
          this.isInTime = getCurrentTime() < this.activity.endTime
          console.log("getCurrentTime()",getCurrentTime())
          console.log("this.activity.endTime",this.activity.endTime)
          console.log("getCurrentTime() < this.activity.endTime",getCurrentTime() < this.activity.endTime)
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.activity-container {
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}

.content {
  min-height: 20rem;
  overflow-y: scroll;
  max-height: 43rem;
  padding: 0 20px;
}

.pushItem {
  width: 94%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  text-align: left;
}

.tags {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 10;
}

.pushItemName {
  height: 3em;
  border-bottom: 1px solid #efefef;
}

.noneImg {
  width: 480px;
  height: 288px;
  background-color: #efefef;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
}
</style>