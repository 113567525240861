<template>
  <div class="flex flex-row flex-jsb" v-if="type == 0">
    <div>
      <span class="font-sb-wb color-sp">{{ title.slice(0, 2) }} </span>
      <span class="font-sb-wb font-24 color-p ml-5"> · </span>
      <span class="font-sb-wb "> {{ title.slice(2, title.length) }} /</span>
      <p class="font-mb-wb color-sp">{{ py }}</p>
    </div>
    <div class="flex-row flex-center color-sp curp" @click="callBack" v-if="showMore">
      <span class="mr-10">查看更多</span>
      <i class="el-icon-circle-plus"></i>
    </div>
  </div>
  <div class="flex flex-row flex-jsb " v-else-if="type == 1">
    <div class="flex-row flex-c flex-ae">
      <span class="font-sb-wb color-sp">{{ title.slice(0, 2) }} </span>
      <!-- <span class="font-sb-wb font-24 color-p ml-5"> · </span> -->
      <span class="font-sb-wb "> {{ title.slice(2, title.length) }} /</span>
      <p class="color-sp color-gray2 font-wl ">{{ py }}</p>
    </div>
    <div class="flex-row flex-center color-sp flex-ae curp" @click="callBack" v-if="showMore">
      <span class="mr-10">查看更多</span>
      <i class="el-icon-circle-plus"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  props: {
    type: {
      default: 0,
      type: Number,
    },
    py: {
      default: "LAN MU BIAO TI ",
      type: String,
    },
    title: {
      default: "栏目标题",
      type: String,
    },
    showMore: {//是否展示“查看更改按钮”
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {};
  },
  methods: {
    callBack() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../theme/myStyle.less");
</style>