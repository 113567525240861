import COS from 'cos-js-sdk-v5' // 引入腾讯云的包
// 需要实例化
const cos = new COS({
  SecretId: 'AKIDzoSt4YnlSt2xc1DJCqV5TcWAbmiNxOYG',
  SecretKey: 'tXw5LkLhpkRsHQJwKRJrLaZcU7drrb5L'
}) // 实例化的包 已经具有了上传的能力 可以上传到该账号里面的存储桶了
let env = process.env.VUE_APP_ENV;
let Bucket = {};
console.log('env', env)
if (env == 'development') {
  Bucket = {
    Bucket: 'test-1303829981', // 存储桶名称
    Region: 'ap-shanghai', // 存储桶地域
  }
} else if (env = 'production') {
  Bucket = {
    Bucket: 'culture-1303829981', // 存储桶名称
    Region: 'ap-shanghai', // 存储桶地域
  }
}
const myUpload = (params, dataCallBack) => {
  if (params.file) {
    //  上传文件到腾讯云
    cos.putObject({
      // 配置
      // Bucket: 'culture-1303829981', // 存储桶名称
      // Region: 'ap-shanghai', // 存储桶地域
      ...Bucket,
      Key: `culture/pc_front/${params.file.name}`, // 文件名作为key
      StorageClass: 'STANDARD', // 此类写死
      Body: params.file, // 将本地的文件赋值给腾讯云配置
      // 进度条
      onProgress: (params) => {
        // this.percent = params.percent * 100
      }
    }, (err, data) => {
      console.log("你上传的 ", err)
      console.log("你上传的 ", data)
      // 需要判断错误与成功
      if (!err && data.statusCode === 200) {
        let thatData = data;
        thatData.Location = 'http://' + thatData.Location
        dataCallBack(thatData);

      }
    })
  }
};
export default myUpload