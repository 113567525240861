<template>
  <div>
    <el-descriptions class="margin-top" title="用户个人信息" :column="3" size="medium" border>
      <template slot="extra">
        <el-button type="primary" size="small" @click="callBack">修改</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          用户名
        </template>
        {{ userInfo.wx_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{ userInfo.tel }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          创建时间
        </template>
        {{ userInfo.reqisterTime||'保密' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          等级
        </template>
        <el-tag size="small">{{userInfo.level}}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          联系地址
        </template>
        {{userInfo.location}}
      </el-descriptions-item>

    </el-descriptions>
    <el-dialog :close-on-click-modal="false"  title="修改信息" :visible.sync="dialogVisible" width="25rem" :before-close="handleClose">
      <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
        :before-upload="beforeAvatarUpload" :http-request="upload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>

      <el-form label-position="left" label-width="80px" :model="updateUserInfo">
        <el-form-item label="用户名称">
          <el-input v-model="updateUserInfo.wx_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="updateUserInfo.tel"></el-input>
        </el-form-item>
        <el-form-item label="邮箱号码">
          <el-input v-model="updateUserInfo.email"></el-input>
        </el-form-item>
        <el-form-item label="个性签名">
          <el-input v-model="updateUserInfo.wx_sign"></el-input>
        </el-form-item>
        <el-form-item label="联系地址">
          <el-input v-model="updateUserInfo.location"></el-input>
        </el-form-item>
        <!-- <el-form-item> -->
        <el-button type="primary" @click="submitForm('ruleForm')" plain>
          <p style="padding: 0 2rem;">保存信息</p>
        </el-button>
        <!-- </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import commmonApi from '../../api/request/common.js';
import myUpload from "../../util/myUpload.js"
export default {
  name: "userInfo",
  props: {
    userInfo: {},
  },
  created() {
    this.$nextTick(() => {
      if (this.userInfo) {
        this.imageUrl = this.userInfo.wx_head;
        this.updateUserInfo = this.userInfo;
      }
    })
  },
  data() {
    return {
      updateUserInfo: {},
      dialogVisible: false,
      imageUrl: '',//
    };
  },
  methods: {
    upload(params) {
      myUpload(params, (data) => {
        this.userInfo.wx_head = data.Location
        this.imageUrl = data.Location
      })
    },
    submitForm() {
      let mobile = /^1[3-9][0-9]{9}$/
      if (this.updateUserInfo.tel && !mobile.test(this.updateUserInfo.tel)) {
        this.$message("手机号联系方式不对")
        return
      }
      commmonApi.updateUserInfo({
        ...this.updateUserInfo
      }).then(res => {
        if (res.success) {
          this.$message.success("修改成功")
          this.updateUserInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$emit("changeUserInfo", this.updateUserInfo)
          this.handleClose();
        }
      })
    },
    callBack() {
      this.$emit("changeInfo");
      this.dialogVisible = true;
    },
    // 上传图片之前
    beforeAvatarUpload() { },
    // 关闭弹框
    handleClose(e) {

      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式



.avatar-uploader .el-upload {
  border: 1px dashed #a24b4b;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  border: 1px solid#bfbfbf;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  color: #8c939d;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
}

.avatar {
  width: 4rem;
  height: 4rem;
  display: block;
}
</style>