
import { get, post, postImg } from "@/util/http"
import ApiContainer from "../url"
const venueApi = {
  getVenueList: params => get(ApiContainer.getVenueList, params),  //获取场馆列表
  getVenueInfoById: params => get(ApiContainer.getVenueInfoById, params),//通过ID获取详情
  updateVenue: params => post(ApiContainer.updateVenue, params),//更新信息
  preventRoom: params => post(ApiContainer.preventRoom, params),//预约房间
  queryBookDate: params => get(ApiContainer.queryBookDate, params),//查询该房间预约数据
  queryBookList: params => get(ApiContainer.queryBookList, params),//获取预约数据
  queryBookListById: params => get(ApiContainer.queryBookListById, params),//获取预约数据(个人)
}
export default venueApi