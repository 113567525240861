<template>
  <div class="flex-1 flex-col mb-20 mh-90h">
    <div class=" w-75rem  bgc-w m-auto flex-1 mt-50 ovf-h bdr-4 flex-row b-shadow ">
      <div class="leftContainer w-20rem bgc-gray ">
        <!-- 用户信息 -->
        <div class="userHead h-10rem bgc-w bgw-1 flex-row flex-ac flex-jsa">
          <el-avatar :size="100" :src="userData && userData.wx_head"></el-avatar>
          <div class="w-60 h-60  flex-col text-left flex-jsa">
            <p class="font-20">{{ userData && userData.wx_name }}</p>
            <div class="mt-10">
              <p class="font-12 color-gray3"> 微信号码：_</p>
              <p class="font-12 color-gray3 t-hd-two"> 个性签名：{{ userData && userData.wx_sign }}</p>
              <p class="font-12 color-gray3"> 登录位置：</p>
              <p class="font-12 color-gray3 t-hd-one"> 登录时间：</p>
            </div>

          </div>
        </div>
        <!-- <div class="userHead h-10rem bgc-w bgw-1 flex-row flex-ac flex-jsa" v-else>

        </div> -->
        <!-- 目录信息 -->
        <div class="left-menu">
          <ul class="flex-col">
            <li v-for="(item, index) in  menuList " :class="index == activeOne ? 'active' : 'inActive'" :key="index"
              @click="setActive(index, item)">
              <div class="ph-10 bgc-w color-p bdw-1 bdc-bs flex-row flex-ac flex-jc"
                v-if="((token && index == 6) || (!token && index == 7)) || (index != 6 && index != 7)">
                <i :class="item.icon ? item.icon : 'el-icon-delete'"></i>
                <p class="ml-10">{{ item.name }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-1 bgc-w ">
        <div class=" p-20" v-show="activeOne == 0">
          <userInfo :userInfo="userData" v-if="userData" @changeUserInfo="changeUserInfo" />
        </div>
        <div class=" p-20 h-90" v-show="activeOne == 1">
          <booking />
        </div>
        <div class=" p-20 h-90" v-if="activeOne == 2">
          <activity />
        </div>
        <div class=" p-20" v-show="activeOne == 3">
          <collect />
          <!-- <like/> -->
          <!-- <visitPlanItem v-for="item in 5" :key="item"/> -->
        </div>
        <div class=" p-20" v-show="activeOne == 4">
          <like />
          <!-- <visitPlanItem v-for="item in 5" :key="item"/> -->
        </div>
        <div class=" p-20 h-90" v-show="activeOne == 5">
          <feedBack />
        </div>
        <div class=" p-20 h-90" v-show="activeOne == 6">
          123123213
        </div>
        <div class=" p-20 h-90" v-show="activeOne == 6">
          <div>123123123</div>
        </div>
      </div>
    </div>
    <!-- 登录的内容 -->
    <el-drawer :title="['账 号 登 录', '邮 箱 登 录', '短 信 登 录'][loginType]" :visible.sync="dialog" direction="ltr"
      custom-class="demo-drawer" ref="drawer">
      <div class="loginContent" v-if="loginType == 0">
        <div class="flex-row flex-ac flex-jc">
          <el-input placeholder="请输入账号" v-model="loginInfo.username" class="loginInput inputPhone">
          </el-input>
        </div>
        <el-input placeholder=" 请输入密码" v-model="loginInfo.password" class="loginInput inputText"></el-input>
      </div>
      <div class="loginContent" v-else-if="loginType == 1">
        <div class="flex-row flex-ac flex-jc">
          <el-input placeholder="请输入邮箱号码" v-model="email" class="loginInput inputPhone">
          </el-input>
          <el-button type="primary" :disabled="isSendingEmail" plain @click="sendText('email')">{{ isSendingEmail ?
            emailTime + 's后再试' : '发送邮箱' }}</el-button>
        </div>
        <el-input placeholder="请输入验证码" v-model="emailText" class="loginInput inputText"></el-input>
      </div>
      <div class="loginContent" v-else-if="loginType == 2">
        <div class="flex-row flex-ac flex-jc">
          <el-input placeholder="请输入手机号" v-model="phone" class="loginInput inputPhone">
          </el-input>
          <el-button type="primary" plain :disabled="isSendingTel" @click="sendText('phone')">{{ isSendingTel ? telTime
            +
            's后重试' : '发送短信' }}</el-button>
        </div>
        <el-input placeholder="请输入验证码" v-model="phoneText" class="loginInput inputText"></el-input>
      </div>
      <div class="loginList">
        <div @click="loginType = 0">
          <el-image class="loginIcon" style="background-color: #16b306"
            src="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/wechat.png"
            fit="cover"></el-image>
        </div>
        <div @click="loginType = 1">
          <el-image class="loginIcon" style=" background-color: #ff7b00;"
            src="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/email.png" fit="cover"></el-image>
        </div>
        <div @click="loginType = 2">
          <el-image class="loginIcon" style="background-color: #007eff"
            src="https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/text.png" fit="cover"></el-image>
        </div>
      </div>
      <div class="loginBtn">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="login" :loading="loading">{{ loading ? '提交中 ...' : '登录'
        }}</el-button>

      </div>
      <div class="goRegiester flex-row flex-ac flex-jc ft-20 color-p curp" @click="goPage('/regiest')">还没账号,前往注册 <i
          class="el-icon-right"></i></div>
    </el-drawer>

  </div>
</template>
  
<script>
import {
  userInfo,
  booking,
  activity,
  feedBack,
  like,
  visitPlanItem,
  collect,
} from "@/components/index";
import commmonApi from '../../api/request/common';

import { setToken, removeToken } from "@/util/auth"
export default {
  name: "user",
  components: { userInfo, booking, activity, feedBack, like, visitPlanItem, collect },
  data() {
    return {
      activeOne: 0, //被激活的
      dialog: false,//登录框
      loading: false,//登陆的时候是否等待
      phone: '',//手机号
      phoneText: '',//手机号码
      email: '',
      emailText: "",//邮箱号码
      loginType: 0,//登录的类型 1-微信扫码，2-短信，3-邮箱
      userData: {},
      dialogVisible: false,
      isSendingEmail: false,//是否正在发送短信
      emailTime: 60,//倒计时
      emailTimer: undefined,//邮箱的定时计时器
      isSendingTel: false,//是否正在发送短信
      telTime: 60,//短信倒计时
      telTimer: undefined,//短信的定时计时器
      token: '',
      loginInfo: {
        username: '',
        password: '',
      },
      menuList: [
        {
          // icon: "el-icon-s-order",
          icon: "el-icon-user-solid",
          name: "个人信息",
        },
        {
          icon: "el-icon-message-solid",
          name: "场馆预约",
        },
        {
          icon: "el-icon-s-flag",

          name: "报名活动",
        },
        {
          icon: "el-icon-s-opportunity",

          name: "收藏攻略",
        },
        {
          icon: "el-icon-star-on",
          name: "往期点赞",
        },
        {
          icon: "el-icon-s-comment",
          name: "建议反馈",
        },
        {
          icon: "el-icon-s-promotion",
          name: "退出登录",
          func: this.logout,
        },
        {
          icon: "el-icon-s-promotion",
          name: "登录",
          func: this.openlogin,
        },
      ],
    };
  },
  created() {
    let userData = localStorage.getItem('userInfo');
    this.userData = JSON.parse(userData);
    this.token = this.$store.state.user.token;//获取用户token
    if (!this.token) {
      this.openlogin();
    }
  },
  methods: {
    changeUserInfo(e) {
      this.userInfo = e;
    },
    goPage(e, query) {
      if (query) {
        this.$router.push({ path: e, query: query })
      } else {
        this.$router.push(e)
      }
    },
    login() {
      if (this.loginType == 0) {
        commmonApi.login({
          ...this.loginInfo
        }).then(res => {

          if (res.success) {
            this.userData = res.data;
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('userInfo', JSON.stringify(res.data))
            setToken(res.data.token)
            this.$store.commit('user/SET_USERINFO', res.data)
            this.$message({
              message: '登录成功',
              type: 'success'
            })
          } else {
            this.$message(res.msg)
          }

        })
      } else {
        commmonApi.loginByText({
          account: this.loginType == 1 ? this.email : this.phone,
          text: this.phoneText,
        }).then(res => {
          if (res.success) {
            this.$message({
              message: '登录成功',
              type: 'success'
            });
            this.userData = res.data;
            localStorage.setItem('token', res.data.token)
            setToken(res.data.token)
            localStorage.setItem('userInfo', JSON.stringify(res.data))
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }
    },
    // 发送短信
    sendText(type) {
      if (type == 'email') {
        let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
        if (this.email == undefined || this.email == '') {
          this.$message.warning("请输入邮箱号码")
        } else if (reg.test(this.email)) {
          commmonApi.email({
            email: this.email
          }).then(res => {
            if (res.success) {
              this.isSendingEmail = true;
              this.emailTimer = setInterval(() => {
                if (this.emailTime > 1) {
                  this.emailTime--;
                } else {
                  this.isSendingEmail = false;
                  clearInterval(this.emailTimer)
                }
              }, 1000);
              this.$message({
                type: "success",
                message: "发送成功，请稍后查阅邮箱!",
              });
            }
          })
        } else {
          this.$message.warning("请输入正确的邮箱号码")
        }
      } else if (type == 'phone') {
        let reg = /^1[3-9]\d{9}$/
        if (this.phone == '' || this.phone == undefined) {
          this.$message.warning("请输入手机号码")
        } else if (reg.test(this.phone)) {
          commmonApi.sms({
            phone: this.phone
          }).then(res => {
            if (res.success) {
              this.isSendingTel = true;
              this.telTimer = setInterval(() => {
                if (this.telTime > 1) {
                  this.telTime--;
                } else {
                  this.isSendingTel = false;
                  clearInterval(this.telTimer)
                }
              }, 1000);
              this.$message({
                type: "success",
                message: "发送成功，请稍后查阅邮箱!",
              });
            }
          })
        } else {
          this.$message.warning("手机号有误")
        }
      }
    },
    // 打开用户修改资料的弹框
    openlogin() {
      this.dialog = true;
      // 赋值
    },
    setActive(e, item) {
      if (this.token || e == 7) {
        if (![6, 7].includes(e)) {
          this.activeOne = e;
        }
        if (item.func) {
          item.func();
        } else {

        }
      } else {
        this.$message("请先登录")
      }
    },
    logout() {
      this.$confirm("您希望退出登录状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear('userInfo')
          localStorage.clear('token')
          removeToken()
          this.$message({
            type: "success",
            message: "登出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消登出",
          });
        });
    },
    cancelForm() {
      this.dialo = false;
    },
  },
};
</script>
  
<style lang="less" scoped>
@import url("../../theme/myStyle.less");

.leftContainer {
  border: 0px solid @BorderLight;
  border-right-width: 1px;

  .userHead {}

  .left-menu {
    li {
      cursor: pointer;
    }

    li:hover {
      color: #fff;
      background-color: @shadowPrimary;
    }

    .active {
      color: #fff;
      background-color: @shadowPrimary;
    }

    .inActive {}
  }
}

.loginContent {
  width: 80%;
  margin-top: 2rem;
  margin: 0 auto;

  .inputText {
    margin-top: 1rem;
  }

}

.loginBtn {
  margin-top: 1rem;
}

.loginList {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.loginIcon {
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.send-text {
  border: 1px solid #d5d5d5;
  padding: 9px 10px;
  margin-left: 10px;
  border-radius: 5px;
  width: 6rem;
  cursor: pointer;
}

.goRegiester {
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  right: 10px;
}

.goRegiester:hover {
  text-decoration: underline;
}

::v-deep .demo-drawer {
  min-width: 500px;
}
</style>