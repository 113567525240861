<template>
  <div class="flex-1">
    <swiper :pagination="{ clickable: true }" class="swiper bgc-b">
      <swiper-slide class="bgc-c" v-for="(item, index) in topList" :key="index" loop autoplay :interval="4000" >
        <div class="color-w relative h-100">
          <el-image style="height:100%;width:100%" :src="item.img" fit="cover"></el-image>
          <div class="sign-box">
            <h1 class="h-4rem mb-20">{{ item.name }}</h1>
            <h3>{{ item.showTime }}</h3>
            <div class=" details font-24 flex-row">
              <p class="color-w">于</p>
              <p class="location ml-20"> {{ item.showPlace }}</p>
            </div>
            <div class="btn" @click="goPage(item)">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="container bgc-w w-75rem m-auto mb-40">
      <tabBar class=" m-auto mt-20 w-90" title="豫剧展示" :type="1" py="CHANG GUAN ZHAN SHI " @onClick="goPage('/venueList')"
        :showMore="false" />
      <div class="w-90  m-auto mt-20 flex flex-row flex-jsb">
        <div class="w-70 bdw-1 pb-10 bdc-bs">
          <mySection title="豫剧团队" />
          <div class="yuItemList flex flex-row flex-wrap  flex-jsb " v-html="yuList[0].content">
            <!-- <yuItem v-for="(item, index) in yuList" :key="index" :name="item.name" :img="item.img" :intro="item.detais"
              @onClick="goDetails(item)" /> -->

            <!-- <yuItem :isMore="true" /> -->
          </div>
        </div>
        <div class="w-30 ml-10 bdw-1 bdc-bs bdr-4">
          <!-- <div class="teamList">
            <yuTeamItem v-for="(item, index) in yuList" :key="index" :name="item.name" :detais="item.detais"
              :leader="item.leader" :leaderTel="item.leaderTel" :place="item.place" :time="item.time" :img="item.img" />
          </div> -->
          <el-carousel height="150px" width="100%">
            <el-carousel-item v-for="item in introBanner" :key="item">
              <div class="w-100 h-100">
                <el-image style="width: 100%; height: 100%" :src="item" fit="cover"></el-image>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div>
            <div class="cameFrom">豫剧介绍</div>
            <div class="detials font-16 mt-10">
              <p>豫剧，是中国五大戏曲剧种之一、中国第一大地方剧种，是主要流行于河南省、河北、山东，流传中国各地的传统戏剧，国家级非物质文化遗产之一。</p>
              <p>豫剧与京剧、越剧同为中国戏曲三鼎甲，
                传承已有上百年的历史，早在清代乾隆年间，已成为河南很有影响的戏曲剧种。豫剧在生成和发展时期，汲取了昆腔、吹腔、皮簧及其他梆子声腔剧种的艺术因素，同时广泛吸收河南民间流行的音乐、曲艺说唱和俗曲小令，形成了朴直淳厚、丰富细腻、富于乡土气息的剧种特色。
                豫剧被西方人称赞是“东方咏叹调”“中国歌剧”等。</p>
              <p>2006年5月20日，河南省申报的豫剧被国务院列入第一批国家级非物质文化遗产名录，编号为Ⅳ-23。</p>
              <p>2008年6月7日，河北省邯郸市申报的豫剧（桑派）被列入第二批国家级非物质文化遗产名录，编号为Ⅳ-23。</p>
              <p>2021年5月24日，新疆生产建设兵团申报的豫剧经国务院批准列入第五批国家级非物质文化遗产代表性项目名录扩展项目名录，编号为Ⅳ-23。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabBar, yuItem, mySection, yuTeamItem } from "@/components/index";
import teamApi from "@/api/request/team";
export default {
  name: "yuTeam",
  components: { tabBar, yuItem, mySection, yuTeamItem },
  data() {
    return {
      yuList: [],//团队清单
      menuList: [],//团队清单
      topList: [],//放在轮播图
      introBanner: [
        'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/pc_back/yujuIntro01.png',
        'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture%2Fpc_back%2Fyujuintro02.jpg',
        'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture%2Fpc_back%2Fyujuintro03.jpg',
        'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture%2Fpc_back%2Fyujuintro04.jpg',
      ],//介绍的轮播
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      queryInfo: {
        pageNo: 1,
        pageSize: 999,
      }
    }
  },
  created() {
    this.yuTeamItem();
    this.getMenu();
  },
  mounted() { },
  methods: {
    goPage(e) {
      this.$router.push({
        path: '/YuTeam/yuDetails?id=' + e.id
      })
    },
    goDetails(e) {
      this.$router.push({
        path: '/YuTeam/teamDetails?id=' + e.id
      })
    },
    getMenu() {
      teamApi.getMenuList({
        ...this.queryInfo,
      }).then(res => {
        if (res.success) {
          this.topList = res.data.list;
          this.menuList = res.data.list;
        }
      })
    },
    yuTeamItem() {
      teamApi.teamList({
        isYu: 1,
      }).then(res => {
        if (res.success) {
          this.yuList = res.data.list;
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样

.swiper {
  height: 45rem;

  .sign-box {
    text-align: left;
    position: absolute;
    padding: 2rem;
    right: 10rem;
    top: 10rem;
    width: 24rem;
    height: 20rem;
    // background-color: #452a0e50;
    background-color: #00000050;
    border-radius: 1rem;

    // border: 0.2rem solid @shadowPrimary;
    h1 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.6rem;
      margin: 2rem 0 1rem 0;
    }

    .details {
      margin-top: 1rem;
      margin-bottom: 1rem;

      .location {
        font-size: 1.6rem;
        color: #f0994a;
      }
    }

    .btn {
      cursor: pointer;
      display: inline-block;
      border: 1px solid #f0994a;
      padding: 0.4rem 1rem;
      font-size: 1.2rem;
      color: #f0994a;
      border-radius: 0.2rem;
      position: absolute;
      bottom: 2rem;
      left: 2rem;
    }

  }
}

.container {
  min-height: 20em;
  margin-top: 3rem;
  padding: 1rem 0;

  // background-color: ;
  .yuItemList {
    padding: 0 2em;
  }
}

.teamList {
  height: 480px;
  overflow-y: scroll;
}

.cameFrom {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.detials {
  color: #00000090;
  text-indent: 2em;
  line-height: 1.5em;
  letter-spacing: 2px;
  text-align: left;
  height: 18em;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 15px;
}
</style>