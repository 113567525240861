import { getToken, setToken, removeToken } from '@/util/auth'


const state = {
  token: getToken(),
  userInfo: {},
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_USERINFO: (state, info) => {
    state.userInfo = { ...info };
  },
};
const actions = {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}