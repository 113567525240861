const share = {
  share2QQ({ url, desc, title }) {
    let myUrl = url || "https://www.mi.com/?masid=2701.0318"; // 需要分享的网址
    let myDesc = desc || "你的分享描述信息";
    let myTitle = title || "这是分享的标题,just test share"; // 分享的标题
    const params = "url=" +  encodeURI(myUrl) +  "&title=" +  encodeURI(myTitle)+ "&desc=" +  encodeURI(myDesc);
    var urlData =
      "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" + params;
      console.log("urlData",urlData)
    window.open(urlData, "_blank");
  }
}
export default share