<template>
  <div class="flex-1">
    <div class="videBox">
      <div v-if="details.videoUrl" style="height:100%;width:100%" > 
      <video controls   object-fit="fill"
        playsinline="true"
        webkit-playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="landscape|portrait"
        x-webkit-airplay="deny"
        disableRemotePlayback="true" :poster="details.img" style="height:100%;width:100%"  :src="details.videoUrl"></video> </div>
      <iframe v-else-if="details.bvid" style="height:100%;width:100%" :src="'//player.bilibili.com/player.html?bvid=' + details.bvid + '&page=1'"
        scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="false"> </iframe>
    </div>
    <div class="videoInfo">
      <div class="title"> {{ details.name }}</div>
      <div class="createTime"> 创建时间：{{ details.createTime }}</div>

      <div class="intro"> 详情介绍：{{ details.intro }}</div>
    </div>
  </div>
</template>

<script>
import { tabBar, yuItem, mySection } from "@/components/index";
import teamApi from "../../api/request/team";

export default {
  name: "yuDetails",
  components: { tabBar, yuItem, mySection },
  data() {
    return {
      yuId: '',
      bvid: '',
      details: {},
    };
  },
  mounted() {
    let query = this.$route.query;
    if (query && query.id) {
      this.yuId = query.id
      this.getData();
    }

  },
  methods: {
    getData() {
      teamApi.getMenuById({
        id: this.yuId
      }).then(res => {
        console.log(res);
        if (res.success) {
          this.details = res.data;
        }
      })
    },
  },
};
</script>

<style lang="less" scope>
.videBox {
  width: 70rem;
  margin: 0 auto;
  height: 38rem;
  margin-top: 1rem;
  overflow: hidden;
}

.videoInfo {
  width: 66rem;
  margin: 0 auto;
  height: 8rem;
  background-color: #ffffff;
  text-align: left;

  padding: 1rem 2rem;

  .title {
    font-size: 20px;
  }

  .createTime {
    font-size: 12px;
    color: #999999;
    margin-top: 5px;
  }



  .intro {
    font-size: 14px;
    margin-top: 5px;
  }
}

.bpx-player-relation-button {
  display: none !important;
}
video::-internal-media-controls-download-button {display:none;
}
video::-webkit-media-controls-enclosure {overflow:hidden;
}
video::-webkit-media-controls-panel {width: calc(100% + 30px); 
}
</style>