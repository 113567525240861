// const HTTP = 'http://10.9.81.139:80'//写自己的后台的域名或者ip地址,或者localhost
const HTTP = process.env.VUE_APP_API_URL//写自己的后台的域名或者ip地址,或者localhost
const IMG_BASE_URL = process.env.VUE_APP_IMG_BASE_URL;//基础图片路径
// 设置全局对象，基础图片路径

const ApiContainer = {
  http: HTTP,
  IMG_BASE_URL: IMG_BASE_URL,//图片前缀
  // 公共接口
  likeRecord: 'likeRecord',//点赞
  likeDigtal: 'likeDigtal',//点赞的数据
  queryLikeDigtal: 'queryLikeDigtal',//查询登录的用户的喜欢的数据
  adminKey: 'admin/getAdminKey',// 关键字
  getVRlist: 'getVRlist',//VR列表
  getVrRoomList:'getVrRoomList',//获取vr房间的列表
  updateVrRoom:'updateVrRoom',//更新房间信息，
  deleteVrRoom:'deleteVrRoom',//删除vr房间信息
  getLiveToken: 'getLiveToken',//获取直播Token
  readCircle: 'readCircle',//获取circle数据
  readLike: 'readLike',//获取条形图数据
  readCutNum: 'readCutNum',//获取折线图数据
  getLiveList: 'getLiveList',//查询直播列表
  getNewerLive: 'getNewerLive',//获取最新的直播的信息
  feedBackList: 'feedBackList',//意见反馈列表
  updateFeedBack: 'updateFeedBack',//更新意见反馈
  addFeedBack: 'addFeedBack',//新增意见反馈
  // 用户相关
  sms: 'users/sms',//发送短信验证码
  email: 'users/email',//发送手机验证嘛
  loginByText: 'users/loginByText',//验证码登录
  register: '/users/register',//用户注册，普通注册
  login: 'users/login',//普通账号密码登录
  updateUserInfo: 'users/updateUserInfo',//更改用户信息
  checkHasUser: 'users/checkHasUser',//注册前先获取手机号是否可以注册
  // 文化活动相关接口
  getActivityList: 'activity/activityList',//获取活动列表
  getActivityEnrollList: 'activity/getActivityEnrollList',//获取活动报名信息
  getActivityInfoById: 'activity/getActivityInfoById',//通过id获取详情
  signActivity: 'activity/signActivity',//报名活动
  updateActivity: 'activity/updateActivity',//活动报名
  queryActivityEnroll: 'activity/queryActivityEnroll',//查询单挑信息
  getTrendsList: 'activity/getTrendsList',//文化动态列表
  updateTrends:'activity/updateTrends',//更新文化动态
  getTrendsDetails:'activity/getTrendsDetails',//获取文化动态详情
  // 文化馆相关接口
  getVenueList: 'venue/getVenueList',//获取场馆列表
  getVenueInfoById: 'venue/getVenueInfoById',//通过ID查询场馆信息
  updateVenue: 'venue/updateVenue',//更新场馆信息
  preventRoom: 'venue/preventRoom',//预约房间
  queryBookDate: 'venue/queryBookDate',//查询预约数据（日期）
  queryBookList: 'venue/queryBookList',//查询预约数据（列表）
  queryBookListById: 'venue/queryBookListById',//获取预约数据（列表）
  // 地图接口
  mapList: 'map/getMapList',//获取地图列表
  updateMap: 'map/updateMap',//更新地图信息
  addYuan: 'map/addYuan',//新增袁世凯故居内容
  getYuanList: 'map/getYuanList',//查询袁世凯故居列表
  deleteYuan: 'map/deleteYuan',//删除袁世凯故居数据
  updateYuan: 'map/updateYuan',//更新袁世凯故居数据
  getMapImagelist: 'map/getMapImagelist',//获取图片信息
  // 数字资源接口
  digitalList: 'digital/digitalList',
  // 景区接口
  getScenicPointList: 'scenic/getScenicPointList',//获取景区列表
  getScenicPointInfoById: 'scenic/getScenicPointInfoById',//通过Id获取景点详情
  updateScenicPoint: 'scenic/updateScenicPoint',//
  // 旅行攻略接口
  getScenicVisitList: 'visit/getScenicVisitList',//旅行攻略列表接口
  queryVisitDetailsById: 'visit/queryVisitDetailsById',//旅行攻略详细查询接口
  updateScenicVisit: 'visit/updateScenicVisit',//更新旅行攻略
  // 团队接口
  teamList: 'team/teamList',//队伍列表接口
  // 节目列表
  getMenuList: 'team/getMenuList',//获取节目列表
  getMenuById: 'team/getMenuById',//通过ID查询豫剧信息
  getMobileVideoList: "team/getMobileVideoList",//获取手机视频列表
  getTeamInfoById:'team/getTeamInfoById',//获取团队的详情
  // 文化站列表
  cultureList: 'culture/CultureList',//查询文化站列表
  getCultureInfoById: 'culture/getCultureInfoById',//通过ID查询数据
  getEnforcementList: 'culture/getEnforcementList',//获取文化执法列表
  // 文化非遗获取列表接口
  heritageList: 'culture/heritageList',//获取文化执法列表
}
export default ApiContainer