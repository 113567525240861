<template>
  <div class="activity-container">
    <div class="w-75rem m-auto">
      <div class="mt-20">
        <myBreadCrumb :list="menuList" />
      </div>
      <!-- 顶部标题 -->
      <div class="w-100 ml-5 h-20rem bgc-w mt-20 flex-row flex-ac bdr-4 b-shadow">
        <el-image class="h-90 w-30rem ml-20 bdr-4" :src="venueInfo.headPic" fit="cover"></el-image>
        <div class="h-90 ml-20  flex-col font-wl flex-1 text-left">
          <div class="color-p font-1_5rem ">{{ venueInfo.name }}</div>
          <div class="color-gray2 font-0_8rem mt-40">联系人：{{ venueInfo.contactMan }}</div>
          <div class="color-gray2 font-0_8rem mt-10">联系电话:{{ venueInfo.contactTel }}</div>
          <div class="color-gray2 font-0_8rem mt-10">地址：{{ venueInfo.location }}</div>
          <div class="color-gray2 font-0_8rem mt-10 pr-30 t-hd-four flex-1">简介：<span>{{ venueInfo.intro }}</span></div>
          <div class="flex-row flex-je mr-30">
            <div class="mt-20 ">
              <el-button icon="el-icon-view" circle type="primary" plain></el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ venueInfo.watchNum }}</span>
            </div>
            <div class="mt-20 ml-20 ">
              <el-button icon="el-icon-thumb" circle type="primary" plain @click="like"></el-button>
              <span class="ml-10 font-1_3rem color-gray">{{ venueInfo.likeNum }}</span>
            </div>

            <div class="mt-20 ml-20">
              <el-button icon="el-icon-share" circle type="primary" plain @click="sharePage(venueInfo)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 下面 -->
      <div class="flex-jsb w-100 flex-1  mt-30 flex-row pb-50">
        <div class="w-65  ph-10 bgc-w mh-50h  ">
          <mySection title="场馆介绍" />
          <div class="content">
            <div class="" v-if="venueInfo.content" v-html="venueInfo.content"> </div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
          <el-divider></el-divider>
          <mySection title="预约内容" />
          <div class="">
            <div v-if="venueInfo.isBook == 1">
              <el-calendar v-model="value" :first-day-of-week="7">
                <template slot="dateCell" slot-scope="{ date,data}">
                  <el-tooltip content="预定此日？" placement="top" effect="light">
                    <div class="w-100 h-100">
                      <div class="alreadyBook" v-if="bookDateList.includes(data.day)">
                        已被预约
                      </div>
                      <div class="preventDay" @click="preventRoom(date, data)" v-else>
                        {{ data.day.split('-').slice(1).join('-') }}
                      </div>
                    </div>
                  </el-tooltip>
                </template>
              </el-calendar>
            </div>
            <!-- <el-empty image="" v-else-if="venueInfo.isBook == 1" description="该房间暂时不可预约"></el-empty> -->
          </div>
        </div>
        <div class="w-30 bgc-w ph-10 h-100">
          <mySection title="热门场馆" />
          <div class="">
            <div v-if="pushList.length">
              <div v-for="(item, index) in pushList" :key="index">
                <div class=" animate__animated animate__fadeInUp pushItem" @click="goPushItem(item)">
                  <div class="tags">
                    <el-tag type="warning" effect="plain"> {{ item.belong }} </el-tag>
                  </div>
                  <el-image style="width: 100%; height: 10em" :src="item.headPic" fit="cover"></el-image>
                  <div class="pushItemName">
                    <div>{{ item.name }}</div>
                    <div class="font-12 color-gray">{{ item.location }}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-else description="暂无介绍"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <!-- 预约打开的弹出框 -->
    <el-dialog :close-on-click-modal="false" class="preventBox" title="预约房间" :visible.sync="dialogTableVisible">
      <el-form ref="form" :model="addForm" label-width="80px">
        <el-form-item label="预约姓名">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="addForm.connect"></el-input>
        </el-form-item>
        <el-form-item label="大约人数">
          <el-input v-model="addForm.peopleNum"></el-input>
        </el-form-item>
        <el-form-item label="预约时间">
          <el-time-select placeholder="起始时间" v-model="addForm.bookStartTime" :picker-options="{
            start: '08:30',
            step: '00:15',
            end: '18:30'
          }">
          </el-time-select>
          <el-time-select placeholder="结束时间" v-model="addForm.bookEndTime" :picker-options="{
            start: '08:30',
            step: '00:15',
            end: '18:30',
            minTime: addForm.bookStartTime
          }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="预约理由">
          <el-input type="textarea" v-model="addForm.whyReq"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { myRadio, myBreadCrumb, mySection } from "@/components/index.js";
import venueApi from "@/api/request/venue.js";
import commmonApi from '../../api/request/common';
import share from "@/util/share";
export default {
  name: "venueDetails",
  components: { myRadio, myBreadCrumb, mySection },
  data() {
    return {
      venueInfo: {},
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      value: new Date(),
      pushList: [],
      dialogTableVisible: false,
      addForm: {},
      bookDate: '',
      bookDateList: [],//已经预约的日期
      // 顶部的目录
      menuList: [
        {
          path: "/Activity",
          name: "文化活动",
        },
        {
          path: "/ActivityList",
          name: "活动列表",
        },
      ],
    };
  },
  created() {
    let id = this.$route.query.id
    this.getVeneueInfo(id);
    this.getBookData(id);
    this.getPushList();
  },
  methods: {
    // 去推荐的页面
    goPushItem(e) {
      this.$router.push({
        path: '/venueDetails',
        query: { id: e.id }
      })
      let loading = this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255, 0.7)'
      });
      setTimeout(() => {
        let id = e.id
        this.getVeneueInfo(id);
        this.getBookData(id);
        this.getPushList();
        loading.close();
      }, 500);
    },
    // 取消
    cancel() {
      this.dialogTableVisible = false;
      this.addForm = {}
    },
    getBookData(e) {
      venueApi.queryBookDate({
        venueId: e
      }).then(res => {
        console.log(res);
        if (res.success) {
          let date = [];
          res.data.map(item => {
            date.push(item.bookDate.split(' ')[0])
          })
          this.bookDateList = date;
        }

      })
    },
    preventRoom(date, data) {
      console.log(date, data)
      this.bookDate = data.day
      this.dialogTableVisible = true;
      // this.$confirm('是否确认预定该房间?', '预定', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   beforeClose: (action, instance, done) => {
      //     if (action === 'confirm') {
      //       instance.confirmButtonLoading = true;
      //       instance.confirmButtonText = '预定中...';
      //       setTimeout(() => {
      //         done();
      //         setTimeout(() => {
      //           instance.confirmButtonLoading = false;
      //         }, 300);
      //       }, 3000);
      //     } else {
      //       done();
      //     }
      //   }
      // }).then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: '删除成功!'
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   });
      // });

    },
    // 预约房间
    onSubmit(e) {
      if (this.venueInfo.isBook == '1') {
        venueApi.preventRoom({
          ...this.addForm,
          venueId: this.venueInfo.id,
          venueName: this.venueInfo.name,
          bookDate: this.bookDate,
        }).then(res => {
          this.dialogTableVisible = false
          if (res.success) {
            this.$message.success(res.msg)
            this.bookDate = {}
          } else {
            this.$message(res.msg)
          }
        })
      } else {
        this.$message("该房间暂不支持预约")
      }
    },
    sharePage(e) {
      share.share2QQ(
        {
          url: 'http://yuyitang.xyz:3333',
          desc: e.content,
          title: e.name,
        }
      )
    },
    watchVenue(e) {
      venueApi.updateVenue({
        id: e,
        watchNum: this.venueInfo.watchNum + 1,
      }).then(res => {

      })
    },
    like() {
      commmonApi.likeRecord({
        partName: '文化场馆',
        partId: this.$route.query.id,
        isLike: '1',
      }).then(res => {
        if (res.success) {
          venueApi.updateVenue({
            likeNum: this.venueInfo.likeNum + 1,
            id: this.$route.query.id,
          }).then(res2 => {
            if (res2.success) {
              this.$message.success("点赞成功")
              this.venueInfo.likeNum = this.venueInfo.likeNum + 1;
            }
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 获取推荐列表
    getPushList() {
      venueApi.getVenueList({
        isDelete: '0',
        push: 1,
        pageNo: 1,
        pageSize: 3,
      }).then(res => {
        if (res.success) {
          this.pushList = res.data.list;
        }
      })
    },
    // 获取详情
    getVeneueInfo(e) {
      venueApi.getVenueInfoById({
        id: e
      }).then(res => {
        if (res.success) {
          this.venueInfo = res.data;
          this.watchVenue(e);
        }
      })
    },
  },

};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.content {

  min-height: 20rem;
  overflow-y: scroll;
  max-height: 33rem;  
  padding: 0 20px;
}

.activity-container {
  caret-color: #00000000 !important;
  flex: 1;
}

/deep/ .el-pagination.is-background .el-pager li {
  background: #fff;
}

/deep/ .btn-next,
/deep/ .btn-prev {
  background: #fff !important;
}

.is-selected {
  color: #1989FA;
}

.preventDay {
  height: 100%;
  width: 100%;
  // background-color: red;
}

.preventBox {
  text-align: left;
  caret-color: #000 !important;
}

.alreadyBook {
  height: 100%;
  color: #ffffff;
  line-height: 4rem;
  cursor: not-allowed;
  background-color: #452a0e;
}

.pushItem {
  width: 94%;
  cursor: pointer;
  margin: 1em auto;
  position: relative;
  text-align: left;
}

.tags {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 10;
}

.pushItemName {
  height: 3em;
  border-bottom: 1px solid #efefef;
}
</style>