<template>
  <div class="scenicSpotItem bdw-1 ovf-h bdr-4  relative flex flex-col" @click="callBack">
    <img class="backImg" :src="pic">
    <el-image class="h-60 w-100" :src="pic" fit="cover"></el-image>
    <div class="word flex-1 text-left p-10">
      <h3 class="color-w">{{ name }}</h3>
      <p class="font-12 mt-10 color-w">时间：{{ openTime }}</p>
      <!-- <p class="font-12 color-w">场景数：1 个</p> -->
      <div class="font-12 color-w flex-row w-100">
        <p class="" style="word-break:keep-all">介绍：</p>
        <p class="t-hd-three">{{ intro }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scenicSpotItem",
  props: {
    name: {
      type: String,
      default: "名称"
    },
    pic: {
      type: String,
      default: "",
    },
    intro: {
      type: String,
      default: '',
    },
    openTime: {
      type: String,
      default: "",
    }
  },
  data() {
    return {};
  },
  methods: {
    callBack() {
      this.$emit("onClick")
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.scenicSpotItem {
  height: 18rem;
  width: 18rem;
  margin-left: 0.2rem;
  background-size: 100%;
  background-color: #ffffff90;
  border-color: @BorderBase;

  .backImg {
    height: 120%;
    width: 100%;
    position: absolute;

  }

  .word {
    background-color: rgba(89, 89, 89, 0.05);
    backdrop-filter: blur(12.5px);
    -webkit-backdrop-filter: blur(12.5px);
    border: 0.666667px solid rgba(255, 255, 255, 0.016);
    box-shadow: rgba(14, 14, 14, 0.19) 0px 6px 15px 0px;
    -webkit-box-shadow: rgba(14, 14, 14, 0.19) 0px 6px 15px 0px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    // color: rgba(128, 128, 128, 0.2);
  }
}
</style>