<template>
  <div class="flex-1 bgc-sp">登录</div>
</template>
  
  <script>
export default {
  name: "login",
  data() {
    return {};
  },
};
</script>
  
  <style lang="less" scoped>
@import url("../../theme/myStyle.less");
</style>