<template>
  <div class="bgc-p bdr-4 p-10">
    <h1 class="color-w pb-10">{{ echartsName }}</h1>
    <div id="bar" :style="{ width: '30rem', height: '20rem', padding: '0 1rem', color: 'ffffff' }"></div>
  </div>
</template>

<script>
export default {
  name: "myBar",
  props: {
    likeNum: {},
    echartsName: {
      default: "点赞记录"
    },
  },
  data() {
    return {
      msg: "fafa",
      originData: [],
      myChart: '',
      xRays: [],
    };
  },
  created() {
    this.$nextTick(async () => {
      this.drawLine();

      if (this.likeNum) {
        let originData = []//坐标的数据
        let xRays = [];//横坐标的标注
        await this.likeNum.map((item, index) => {
          originData[index] = item.num;
          xRays[index] = item.partName
        })
        this.originData = originData
        this.xRays = xRays
        var option = this.myChart.getOption();
        option.series[0].data = originData;
        option.xAxis[0].data = xRays;
        this.myChart.setOption(option);
      }
    })
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById("bar"));
      // 绘制图表
      this.myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            color: "#fff",
            type: "shadow",
          },
        },
        legend: {
          textStyle:{
            color:"#ffffff"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            color: "#fff"
          },
        ],
        series: [
          {
            name: "活动报名数据",
            type: "bar",
            emphasis: {
              focus: "series",

            },
            // 可以修改颜色
            // itemStyle: { color: 'yellow' },
            data: [],
          }
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式
</style>