<template>
  <div class="tabber flex-col ">
    <!-- 顶部的tabber -->
    <myNav @change="changeTab" class="tab" />
    <div class=" w-100 flex-1 flex-col">
    <router-view />
  </div>
    <!-- 回到顶部 -->
    <div @click="goTop" :class="[isShow ? 'animate__backInRight' : 'animate__backOutRight']"
      class="right-tabs animate__animated ">
      <i class="el-icon-arrow-up" style="margin-top:10px"></i>
    </div>
    <myFooter />
  </div>
</template>

<script>
import { myNav, myFooter } from "@/components/index.js";

export default {
  name: "Tabber",
  components: {
    myNav,
    myFooter,
  },
  data() {
    return {
      scroll: 0,
      isShow: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 回到顶部
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    // 监听滚动
    handleScroll(e) {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scroll < 700) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    changeTab(e) {
    },
  },
};
</script>

<style lang="less" scoped>
@import "../theme/myStyle.less"; //全局样式

.tabber {
  // background-color: @BackgroundG;
  background-color: #fff4df;
  background-image: url("../assets/common/bg_common_Image.png");
  background-size: 50%;
  min-height: 100vh;
  min-width: 1280px;

  .tab {
    // position: fixed;
    top: 0%;
    z-index: 99;
    width: 100%;
  }

  .main-body {
    // margin-top: 60px;
    flex: 1;
  }
}

.right-tabs {
  height: 60px;
  width: 60px;
  background-color: #a16222;
  position: fixed;
  right: 20px;
  border-radius: 40px;
  box-shadow: 0 0 10px 0px #452a0e;
  bottom: 10vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
</style>