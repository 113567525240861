<template>
  <div class="homeBody">

    <div class="mainBody">
      <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
        <li v-for="(item) in listData" class="infinite-list-item" :key="item.id">
          <div class="videoItem">
            <iframe style="height:10rem;width:100%"
              :src="'//player.bilibili.com/player.html?bvid=' + item.bvid + '&page=1'" scrolling="no" border="0"
              frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
            <div class="videoName">
              <div class="flex-row flex-jsb flex-ac">
                <p class="name">节目名称：{{ item.name }}</p>
              </div>
              <p class="intro">节目介绍：{{ item.intro }}</p>
              <div class="flex-row flex-jsb">
                <p class="intro">演出地点：{{ item.showPlace }}</p>
                <p class="intro">队伍名称：{{ item.teamName }}</p>
              </div>
              <div class="flex-row flex-jsb flex-ac">
                <p class="intro">演出时间：{{ item.showTime }}</p>
                <p class="time">{{ item.createTime }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import teamApi from "../../api/request/team.js";
export default {
  name: "video",
  data() {
    return {
      listData: [],
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
  },
  created() {
    this.getList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      teamApi.getMobileVideoList({
        ...this.queryInfo,
      }).then(res => {
        if (res.success) {
          this.listData = res.data.list;
        }
      })
    },
  },
};
</script>
<style scoped lang="less">
@import url("../../theme/myStyle.less");

.homeBody {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  .mainBody {
    flex: 1;
    overflow: scroll;

    .videoItem {
      background-color: #fff;
      margin-bottom: 1px;
      padding: 20px 15px 0px;

      .videoName {
        text-align: left;
        font-size: 14px;
      }
    }

    .infinite-list-item {
      border-bottom: 2px solid #ebebeb;

      .name {
        font-size: 18px;
      }

      .intro {
        font-size: 14px;
      }

      .time {
        line-height: 19px;
        font-size: 14px;
        color: #999999;
      }
    }
  }
}
</style>
